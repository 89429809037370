/* eslint-disable */

import axios from 'axios';
import { getHasLocalStorageAuth } from '../helpers/handleStorage';

import { END_POINT_BASE, IS_DEV } from './Api';

// POST

// GET
export const addSchedule = async (
	postId,
	rate,
	startDay,
	startHour,
	endDay,
	endHour,
	note,
	investment,
	ctr_meta,
	ctl_meta,
) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const formData = new FormData();
		formData.append('auth_email', localStorageAuth.data.email);
		formData.append('auth_timestamp', localStorageAuth.data.timestamp);
		formData.append('post_id', postId);
		formData.append('rate', rate);
		formData.append('start_day', startDay);
		formData.append('start_hour', startHour);
		formData.append('end_day', endDay);
		formData.append('end_hour', endHour);
		formData.append('note', note);
		formData.append('investment', investment);
		formData.append('ctr_meta', ctr_meta);
		formData.append('ctl_meta', ctl_meta);
		formData.append('role', localStorageAuth.data.role);

		console.log('d', ctr_meta, ctl_meta);
		console.log('do jei', localStorageAuth.data);

		// const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}`;

		const END_POINT = END_POINT_BASE + '/schedules/add-schedule';
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Content-Type': 'multipart/form-data',
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
					'Content-Type': 'multipart/form-data',
			  };

		await axios({
			method: 'post',
			url: END_POINT,
			data: formData,
			headers: axiosHeader,
		})
			.then((response) => {
				// console.log("yeah", response.data.leads.length)
				console.log('yeah', response.data);

				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							schedule: response.data.schedule,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// GET
export const getSchedules = async (postId) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&post_id=${postId}&role=${localStorageAuth.data.role}`;

		const END_POINT = END_POINT_BASE + '/schedules/schedules' + GET_PARAMS;
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		await axios({
			method: 'get',
			url: END_POINT,
			headers: axiosHeader,
		})
			.then((response) => {
				// console.log("yeah", response.data.leads.length)
				console.log('yeah', response.data.schedules);

				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							schedules: response.data.schedules,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// // GET DATA
// export const getPostData = async (postId) => {
// 	const localStorageAuth = getHasLocalStorageAuth();
// 	const hasLocalStorageAuth = localStorageAuth.status;
// 	let serverResponse = { data: { status: 0 } };

// 	if (hasLocalStorageAuth) {
// 		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}&id=${postId}`;

// 		const END_POINT = END_POINT_BASE + '/posts/data-post' + GET_PARAMS;
// 		await axios({
// 			method: 'get',
// 			url: END_POINT,
// 			headers: { Authorization: `Bearer ${localStorageAuth.data.token}` },
// 		})
// 			.then((response) => {
// 				console.log('data lead', response);
// 				// 1 - done
// 				if (response.data.status === 1) {
// 					serverResponse = {
// 						data: {
// 							status: response.data.status,
// 							post: response.data.post,
// 							message: response.data.message,
// 						},
// 					};
// 				} else {
// 					serverResponse = {
// 						data: {
// 							status: response.data.status,
// 							message: response.data.message,
// 						},
// 					};
// 				}
// 			})
// 			.catch((error) => {});
// 	}

// 	return serverResponse;
// };

// POST delete
export const removeSchedule = async (scheduleId) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const formData = new FormData();
		formData.append('auth_timestamp', localStorageAuth.data.timestamp);
		formData.append('auth_email', localStorageAuth.data.email);
		formData.append('schedule_id', scheduleId);
		formData.append('role', localStorageAuth.data.role);

		const END_POINT = END_POINT_BASE + '/schedules/remove-schedule';
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };
		await axios({
			method: 'post',
			url: END_POINT,
			data: formData,
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('remove schedule', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// POST edit
export const editDataSchedule = async (
	id,
	postId,
	rate,
	startDay,
	startHour,
	endDay,
	endHour,
	note,
	ctr,
	ctl,
	ctrMeta,
	ctlMeta,
	investment,
	conversions,
	visualization,
	clicks,
	ctc,
) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const formData = new FormData();
		formData.append('auth_timestamp', localStorageAuth.data.timestamp);
		formData.append('auth_email', localStorageAuth.data.email);
		formData.append('id', id);
		formData.append('post_id', postId);
		formData.append('start_day', startDay);
		formData.append('start_hour', startHour);
		formData.append('end_day', endDay);
		formData.append('end_hour', endHour);
		formData.append('rate', rate);
		formData.append('note', note);
		formData.append('ctr', ctr);
		formData.append('ctl', ctl);
		formData.append('ctr_meta', ctrMeta);
		formData.append('ctl_meta', ctlMeta);
		formData.append('investment', investment);
		formData.append('conversions', conversions);
		formData.append('visualization', visualization);
		formData.append('clicks', clicks);
		formData.append('ctc', ctc);
		formData.append('role', localStorageAuth.data.role);

		const END_POINT = END_POINT_BASE + '/schedules/edit-schedule';
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };
		await axios({
			method: 'post',
			url: END_POINT,
			data: formData,
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('cjx', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};
