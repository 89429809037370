/* eslint-disable */

import axios from 'axios';
import { getHasLocalStorageAuth } from '../helpers/handleStorage';

import { END_POINT_BASE, IS_DEV } from './Api';

// GET FILES
export const getFiles = async (postId) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&post_id=${postId}&role=${localStorageAuth.data.role}`;

		const END_POINT = END_POINT_BASE + '/posts/files' + GET_PARAMS;
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };
		await axios({
			method: 'get',
			url: END_POINT,
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('myfiles', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							files: response.data.files,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// POST add
export const uploadFile = async (postId, totalFiles, userFile) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const formData = new FormData();
		formData.append('auth_timestamp', localStorageAuth.data.timestamp);
		formData.append('auth_email', localStorageAuth.data.email);
		// formData.append('payment_id', paymentId);
		formData.append('userFile1', userFile);
		// formData.append('userFile2', userFile[1]);
		// formData.append('userFile3', userFile[2]);
		// formData.append('userFile4', userFile[3]);
		// formData.append('userFile5', userFile[4]);
		// formData.append('userFile6', userFile[5]);
		// formData.append('userFile7', userFile[6]);
		// formData.append('userFile8', userFile[7]);
		// formData.append('userFile9', userFile[8]);
		// formData.append('userFile10', userFile[9]);
		// formData.append('userFile11', userFile[10]);
		// formData.append('userFile12', userFile[11]);
		// formData.append('userFile13', userFile[12]);
		// formData.append('userFile14', userFile[13]);
		// formData.append('userFile15', userFile[14]);
		// formData.append('userFile16', userFile[15]);
		// formData.append('userFile17', userFile[16]);
		// formData.append('userFile18', userFile[17]);
		// formData.append('userFile19', userFile[18]);
		// formData.append('userFile20', userFile[19]);
		// formData.append('userFile21', userFile[20]);
		// formData.append('userFile22', userFile[21]);
		// formData.append('userFile23', userFile[22]);
		// formData.append('userFile24', userFile[23]);
		// formData.append('userFile25', userFile[24]);
		// formData.append('userFile26', userFile[25]);
		// formData.append('userFile27', userFile[26]);
		// formData.append('userFile28', userFile[27]);
		// formData.append('userFile29', userFile[28]);
		// formData.append('userFile30', userFile[29]);
		formData.append('id', postId);
		formData.append('total', totalFiles);
		formData.append('role', localStorageAuth.data.role);

		const END_POINT = END_POINT_BASE + '/posts/add-files';
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		// const END_POINT = '/api/app/post-files';
		await axios({
			method: 'post',
			url: END_POINT,
			data: formData,
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('gruop', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// POST delete
export const removeFile = async (postId, fileUrl) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const formData = new FormData();
		formData.append('auth_timestamp', localStorageAuth.data.timestamp);
		formData.append('auth_email', localStorageAuth.data.email);
		formData.append('post_id', postId);
		formData.append('file_url', fileUrl);
		formData.append('role', localStorageAuth.data.role);

		const END_POINT = END_POINT_BASE + '/posts/remove-file';
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };
		await axios({
			method: 'post',
			url: END_POINT,
			data: formData,
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('gol', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};
