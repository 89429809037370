/* eslint-disable */
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import '../../SweetAlertCustom.scss';

import { clearLocalStorageAuth } from '../../helpers/handleStorage';
import { rdxLogoutUser, IRdxUser } from '../../redux/ducks/User';

const HeaderTop = ({ dataClient, btBack = false, isUpdated, title = '' }: any) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const rdxUserisAuth = useSelector((state: IRdxUser) => state.isAuth);
	const rdxUserEmail = useSelector((state: IRdxUser) => state.email);
	const logoutUser = () => {
		Swal.fire({
			title: 'Desconectar',
			text: 'Deseja deslogar do sistema?',
			allowEnterKey: false,
			confirmButtonText: `Confirmar`,
			showCancelButton: true,
			cancelButtonText: `Cancelar`,
			allowEscapeKey: true,
			showCloseButton: true,
			didOpen: () => {
				const closeButtonNewIcon = '<i class="hover:underline">Fechar</i>';
				const swalButtonDefault = document.querySelector('.swal-theme-pes__close_icon');
				if (swalButtonDefault) {
					swalButtonDefault.innerHTML = closeButtonNewIcon;
				}
			},
			showClass: {
				popup: 'swal-theme-pes__animation swal-theme-pes__animation--open ',
			},
			hideClass: {
				popup: 'swal-theme-pes__animation swal-theme-pes__animation--close',
			},
			customClass: {
				container: 'swal-theme-pes__container',
				closeButton: 'swal-theme-pes__close_icon',
				htmlContainer: 'swal-theme-pes__html_container',
				actions: 'swal-theme-pes__actions',
				popup: 'swal-theme-pes__popup w-max-sm',
				title: 'swal-theme-pes__title',
				confirmButton:
					'swal-theme-pes__button swal-theme-pes__button--confirm border block px-3 py-2 w-full rounded-md bg-blue-500 hover:bg-blue-600 text-white shadow mb-3',
				cancelButton:
					'swal-theme-pes__button swal-theme-pes__button--cancel border block px-3 py-2 w-full rounded-md bg-white hover:bg-gray-50 text-gray-600',
				denyButton: 'swal-theme-pes__button swal-theme-pes__button--deny',
			},
		}).then((result: any) => {
			if (result.isConfirmed) {
				clearLocalStorageAuth();
				dispatch(rdxLogoutUser());
				history.push('/');
			}
		});
	};

	return (
		<>
			{rdxUserisAuth && (
				<div className="bg-white py-2 px-5 w-full flex justify-between border-b border-[#EBEBEB]">
					<div className="flex items-center space-x-3">
						<img src="/logo-planner.svg" alt="Logo BP Planner" className="h-10 w-auto" />
						<ul className="flex space-x-3 items-center">
							<li className="text-sm font-bold">
								<p className=" inline-block text-gray-800">Planner</p>
							</li>
							<li className="text-sm ">
								<p className=" inline-block  text-gray-400">/</p>
							</li>
							<li className="text-sm ">
								{btBack ? (
									<button
										onClick={() => {
											if (!isUpdated) {
												if (history.action !== 'POP') history.goBack();
												history.push('/');
											} else {
												if (window.confirm('Ops! Deseja sair sem salvar?')) {
													history.push('/');
												}
											}
										}}
										className="text-[#4131FA] hover:underline flex items-center"
									>
										Dashboard ({dataClient?.companyName})
									</button>
								) : (
									<p className=" inline-block  text-gray-400">
										Dashboard ({dataClient?.companyName})
									</p>
								)}
							</li>
							{btBack && (
								<>
									<li className="text-sm ">
										<p className=" inline-block  text-gray-400">/</p>
									</li>
									<li className="text-sm ">
										<p className=" inline-block  text-gray-400">{title}</p>
									</li>
								</>
							)}
						</ul>
					</div>
					<div className="flex space-x-3 items-center">
						<ul className="flex flex-col items-end">
							<li className="text-sm text-[#3D3D3D] font-bold hidden md:block">{rdxUserEmail}</li>
							<li className="text-gray-400 hover:text-white leading-none">
								<button
									type="button"
									onClick={logoutUser}
									className="text-sm transition hover:text-[#F75069] rounded-md "
								>
									Sair
								</button>
							</li>
						</ul>

						<i className={`bi bi-person-circle  aspect-square text-3xl `} />
					</div>
				</div>
			)}
		</>
	);
};

export default HeaderTop;
