/* eslint-disable */
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import Textarea from 'react-expanding-textarea';

import { editDataLead } from '../../Api/clientLeadsHandle';
import { addPost } from '../../Api/clientPostsHandle';
import { validateForm, IForm } from '../FormValidation';

/*
título do post
descrição
ref
anotação

*/

const LeadAddForm = ({ closeModal, spaces }) => {
	const history = useHistory();
	const [sending, setSending] = useState(false);
	const [formFields, setFormFields] = useState([
		{
			// 0 title
			name: 'title',
			value: '',
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 1 copy
			name: 'copy',
			value: '',
			error: '',
			type: 'text',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 2 space
			name: 'space',
			value: spaces[0].id,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 3 channel - facebook
			name: 'channel_facebook',
			value: 0,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 4 channel - instagram
			name: 'channel_instagram',
			value: 0,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 5 channel - newsletter
			name: 'channel_newsletter',
			value: 0,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 6 channel - googleads
			name: 'channel_googleads',
			value: 0,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 7 channel - whatsapp
			name: 'channel_whatsapp',
			value: 0,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 8 channel - twitter
			name: 'channel_twitter',
			value: 0,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 9 channel - linkedin
			name: 'channel_linkedin',
			value: 0,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 10 note
			name: 'note',
			value: '',
			error: '',
			type: 'text',
			maxLength: 2000,
		},
	]);

	// handle input change
	const handleChange = (e) => {
		const isCheckBox = e.target.type === 'checkbox';
		let currentValue = e.target.value;

		// OPTIONAL: clean spaces
		if (
			e.target.name === 'email' ||
			e.target.name === 'password' ||
			e.target.name === 'passwordConfirm' ||
			e.target.name === 'phone'
		) {
			currentValue = currentValue.replace(/\s/g, '');
			currentValue = currentValue.toLowerCase();
		}
		setFormFields(
			formFields.map((field) => {
				if (field.name === e.target.name) {
					return {
						...field,
						value: isCheckBox ? e.target.checked : currentValue,
						error: '',
					};
				}
				return { ...field };
			}),
		);
	};

	// validation form
	const validationForm = () => {
		let hasNoErrors = true;
		const inputRequired = validateForm(formFields, setFormFields);
		if (hasNoErrors) {
			hasNoErrors = inputRequired.hasPassed;
		}
		return hasNoErrors;
	};

	// conclusion
	const handleSubmitAll = async (e) => {
		if (!sending) {
			e.preventDefault();
			const isValid = validationForm();

			if (isValid) {
				const toastId = toast.loading('Salvando...');
				setSending(true);
				addPost(
					formFields[0].value,
					formFields[1].value,
					formFields[2].value,
					formFields[3].value,
					formFields[4].value,
					formFields[5].value,
					formFields[6].value,
					formFields[7].value,
					formFields[8].value,
					formFields[9].value,
					formFields[10].value,
				)
					.then((response) => {
						if (response.data.status === 1) {
							console.log('done added post', response);
							toast.success('Post adicionado', {
								id: toastId,
							});
							history.push(`./post/${response.data.post.id}`);
						} else {
							toast.error(response.data.message, {
								id: toastId,
							});
						}
					})
					.catch((error) => {
						console.log('error', error);
					});
			}

			setSending(false);
		} else {
			// console.log("already sending");
		}
	};

	return (
		<form onSubmit={handleSubmitAll} className="flex flex-col items-stretch h-full">
			<div className="px-5 h-[50px] flex-shrink-0  border-gray-200 flex items-center justify-between">
				<h2 className="text-lg font-bold text-[#FD2E91]">Adicionar post</h2>
				<button
					type="button"
					onClick={() => closeModal('SECOND_MODAL')}
					className="text-blue-500 hover:underline"
				>
					<i className="bi-x text-4xl" />
				</button>
			</div>

			<div className="basis-full h-full bg-white overflow-y-auto px-3 md:px-5 py-3 md:py-5">
				<label htmlFor={formFields[0].name} className="mb-3 md:mb-2 block">
					<span className="block font-semibold mb-1 text-sm">Título:</span>
					<input
						type={formFields[0].type}
						name={formFields[0].name}
						maxLength={formFields[0].maxLength}
						value={formFields[0].value}
						id={formFields[0].name}
						onChange={(e) => handleChange(e)}
						className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
							formFields[0].error.length === 0 ? 'border border-gray-400' : 'border border-red-500'
						}`}
					/>
					<span className="text-red-500 text-xs block">{formFields[0].error}</span>
				</label>

				<label htmlFor={formFields[2].name} className="mb-3 md:mb-2 block w-full">
					<span className="block font-semibold mb-1 text-sm">Space: </span>
					<select
						value={formFields[2].value}
						name={formFields[2].name}
						id={formFields[2].name}
						onChange={(e) => handleChange(e)}
						className={`w-full py-2 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none  ${
							formFields[2].error.length === 0 ? ' border border-gray-400' : 'border border-red-500'
						}
							`}
					>
						{spaces.map((space, index) => {
							return (
								<option
									key={`${space.id}-${index}`}
									value={space.id}
									className="text-gray-600 bg-white"
								>
									{space.name}
								</option>
							);
						})}
					</select>
				</label>

				<label htmlFor={formFields[1].name} className="mb-3 md:mb-2 block">
					<span className="block font-semibold mb-1 text-sm">Copy:</span>
					<Textarea
						name={formFields[1].name}
						rows={3}
						id={formFields[1].name}
						maxLength={formFields[1].maxLength}
						value={formFields[1].value}
						onChange={(e) => handleChange(e)}
						className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
							formFields[1].error.length === 0 ? 'border border-gray-400' : 'border border-red-500'
						}`}
					/>
					<span className="text-red-500 text-xs block">{formFields[1].error}</span>
				</label>

				<label htmlFor={formFields[10].name} className="mb-3 md:mb-2 block">
					<span className="block font-semibold mb-1 text-sm">Anotação:</span>
					<Textarea
						name={formFields[10].name}
						rows={3}
						id={formFields[10].name}
						maxLength={formFields[10].maxLength}
						value={formFields[10].value}
						onChange={(e) => handleChange(e)}
						className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
							formFields[10].error.length === 0 ? 'border border-gray-400' : 'border border-red-500'
						}`}
					/>
					<span className="text-red-500 text-xs block">{formFields[10].error}</span>
				</label>

				<div className="border border-gray-300 p-3 bg-white">
					<label
						htmlFor={formFields[3].name}
						className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
					>
						<input
							type={formFields[3].type}
							name={formFields[3].name}
							maxLength={formFields[3].maxLength}
							checked={Boolean(Number(formFields[3].value))}
							value={formFields[3].value}
							id={formFields[3].name}
							onChange={(e) => handleChange(e)}
							className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
								formFields[3].error.length === 0
									? 'border border-gray-400'
									: 'border border-red-500'
							}`}
						/>

						<span className="text-sm ml-1">Facebook</span>
					</label>
					<label
						htmlFor={formFields[4].name}
						className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
					>
						<input
							type={formFields[4].type}
							name={formFields[4].name}
							maxLength={formFields[4].maxLength}
							checked={Boolean(Number(formFields[4].value))}
							value={formFields[4].value}
							id={formFields[4].name}
							onChange={(e) => handleChange(e)}
							className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
								formFields[4].error.length === 0
									? 'border border-gray-400'
									: 'border border-red-500'
							}`}
						/>

						<span className="text-sm ml-1">Instagram</span>
					</label>
					<label
						htmlFor={formFields[5].name}
						className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
					>
						<input
							type={formFields[5].type}
							name={formFields[5].name}
							maxLength={formFields[5].maxLength}
							checked={Boolean(Number(formFields[5].value))}
							value={formFields[5].value}
							id={formFields[5].name}
							onChange={(e) => handleChange(e)}
							className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
								formFields[5].error.length === 0
									? 'border border-gray-400'
									: 'border border-red-500'
							}`}
						/>

						<span className="text-sm ml-1">Newsletter</span>
					</label>
					<label
						htmlFor={formFields[6].name}
						className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
					>
						<input
							type={formFields[6].type}
							name={formFields[6].name}
							maxLength={formFields[6].maxLength}
							checked={Boolean(Number(formFields[6].value))}
							value={formFields[6].value}
							id={formFields[6].name}
							onChange={(e) => handleChange(e)}
							className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
								formFields[6].error.length === 0
									? 'border border-gray-400'
									: 'border border-red-500'
							}`}
						/>

						<span className="text-sm ml-1">Google Ads</span>
					</label>
					<label
						htmlFor={formFields[7].name}
						className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
					>
						<input
							type={formFields[7].type}
							name={formFields[7].name}
							maxLength={formFields[7].maxLength}
							checked={Boolean(Number(formFields[7].value))}
							value={formFields[7].value}
							id={formFields[7].name}
							onChange={(e) => handleChange(e)}
							className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
								formFields[7].error.length === 0
									? 'border border-gray-400'
									: 'border border-red-500'
							}`}
						/>

						<span className="text-sm ml-1">Whatsapp</span>
					</label>
					<label
						htmlFor={formFields[8].name}
						className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
					>
						<input
							type={formFields[8].type}
							name={formFields[8].name}
							maxLength={formFields[8].maxLength}
							checked={Boolean(Number(formFields[8].value))}
							value={formFields[8].value}
							id={formFields[8].name}
							onChange={(e) => handleChange(e)}
							className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
								formFields[8].error.length === 0
									? 'border border-gray-400'
									: 'border border-red-500'
							}`}
						/>

						<span className="text-sm ml-1">Twitter</span>
					</label>
					<label
						htmlFor={formFields[9].name}
						className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
					>
						<input
							type={formFields[9].type}
							name={formFields[9].name}
							maxLength={formFields[9].maxLength}
							checked={Boolean(Number(formFields[9].value))}
							value={formFields[9].value}
							id={formFields[9].name}
							onChange={(e) => handleChange(e)}
							className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none bg-red-300 ${
								formFields[9].error.length === 0
									? 'border border-gray-400'
									: 'border border-red-500'
							}`}
						/>

						<span className="text-sm ml-1">Linkedin</span>
					</label>
					<label className="flex items-center bg-white  cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto">
						<input
							type="checkbox"
							checked="true"
							readOnly
							disabled
							className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none 'border border-gray-400'`}
						/>

						<span className="text-sm ml-1">Base</span>
					</label>
				</div>
			</div>

			<div className="px-5 h-[70px] flex-shrink-0 border-t border-gray-200 flex items-center justify-end">
				<div className="flex space-x-3 justify-end  ">
					<button
						type="button"
						onClick={() => closeModal('SECOND_MODAL')}
						className="block px-3 py-2 w-auto rounded-md bg-white hover:bg-gray-50 text-gray-600 hover:text-blue-500 flex-shrink-0"
					>
						Cancelar
					</button>
					{!sending ? (
						<>
							<button
								type="submit"
								className="px-3 py-2 w-auto rounded-md bg-[#4131FA] hover:bg-[#3125BC] text-white shadow flex-shrink-0"
							>
								Cadastrar
							</button>
						</>
					) : (
						<div
							type="button"
							className="bg-[#4131FA] hover:bg-[#3125BC] py-2 px-3 text-white rounded-md pr-5 "
						>
							Salvando <i className="animate-spin absolute bi-arrow-clockwise text-md " />
						</div>
					)}
				</div>
			</div>
		</form>
	);
};

export default LeadAddForm;
