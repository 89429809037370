/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line */
import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { getHasLocalStorageAuth } from '../helpers/handleStorage';
import Placeholder from '../components/Placeholder/Placeholder';
import HeaderTop from '../components/HeaderTop/HeaderTop';
import ModalCustom from '../components/ModalCustom/ModalCustom';
import RateStar from '../components/RateStar/RateStar';

import { getLeadData } from '../Api/clientLeadsHandle';
import PaginationJson from '../components/PaginationJson/PaginationJson';

import { END_POINT_BASE } from '../Api/Api';

import { getClientData, getClientCSV } from '../Api/clientsClientHandle';
import Footer from '../components/Footer/Footer';

import 'bootstrap-icons/font/bootstrap-icons.css';
import LeadEdit from '../components/LeadEdit/LeadEdit';
import LeadAddForm from '../components/LeadAddForm/LeadAddForm';
import PostView from '../components/PostView/PostView';
import CalendarView from '../components/CalendarView/CalendarView';
import moment from 'moment';
import FilterButtons from '../components/FilterButtons/FilterButtons';
import FilterModal from '../components/FilterModal/FilterModal';

const PageClientHub = () => {
	const history = useHistory();

	const [dataClient, setDataClient] = useState(null);
	const [fileToken, setFileToken] = useState();
	const [isFilterOpened, setIsFilterOpened] = useState(false);
	const [width, setWidth] = useState(0); // check width size of the window

	// local post
	const [formFieldsPostIdLocal, setFormFieldsPostIdLocal] = useState(0);

	//
	const [hasAllQueries, setHasAllQueries] = useState(true);
	const [messages, setMessages] = useState(null);
	const [updateSearch, setUpdateSearch] = useState(new Date().getTime()); // last update component
	// const [lastTimestampResult, setLastTimestampResult] = useState(''); // last update component
	const [hasSomeFilter, setHasSomeFilter] = useState(false);

	// modal data
	const [leadCurrentData, setLeadCurrentData] = useState(null);

	// rate
	const rateTxt = ['Referência', 'Rascunho', 'Em análise', 'Aprovado'];

	// filter
	const [currentPage, setCurrentPage] = useState(1);
	const [currentOrder, setCurrentOrder] = useState(1);
	const [currentModeview, setCurrentModeview] = useState(1);
	const [currentCalendarday, setCurrentCalendarday] = useState(moment().format('YYYY-MM-DD'));
	const [currentFilter, setCurrentFilter] = useState({
		state: '',
		cities: '',
		name: '',
		email: '',
		whatsapp: '',
		id: '',
		status_1: true,
		status_2: true,
		status_3: true,
		status_4: true,
		status_5: true,

		department_1: true,
		department_2: true,
		department_3: true,
		department_4: true,
		department_5: true,
		department_6: true,
		department_7: true,
		department_8: true,
		// department_9: true,
		// department_10: true,
		// department_11: true,
		// department_12: true,
		// department_13: true,
		// department_14: true,
		// department_15: true,

		space_1: true,
		space_2: true,
		space_3: true,
		space_4: true,
		space_5: true,

		space_id_1: 0,
		space_id_2: 0,
		space_id_3: 0,
		space_id_4: 0,
		space_id_5: 0,
	});

	// console.log('zelda', dataClient.spaces);

	useEffect(() => {
		const query = new URLSearchParams(history.location.search);

		let paramCurrentPage = Number(query.get('page'));

		// table view
		if (currentModeview === 1) {
			if (paramCurrentPage === 0) {
				console.log('NAO TEM PARAMETRO', paramCurrentPage);
				history.push(
					`/dashboard?page=1&calendarday=${currentCalendarday}&order=1&modeview=1&department1=true&department2=true&department3=true&department4=true&department5=true&department6=true&department7=true&department8=true&space1=true&space2=true&space3=true&space4=true&space5=true`,
				);
			}
		}

		let paramCurrentCalendarday = query.get('calendarday');
		// calendar
		if (currentModeview === 2) {
			if (paramCurrentCalendarday === '') {
				console.log('NAO TEM PARAMETRO', paramCurrentCalendarday);
				history.push(
					`/dashboard?page=1&calendarday=${moment().format(
						'YYYY-MM-DD',
					)}&order=1&modeview=1&department1=true&department2=true&department3=true&department4=true&department5=true&department6=true&department7=true&department8=true&space1=true&space2=true&space3=true&space4=true&space5=true`,
				);
			}
		}
		if (dataClient) {
			console.log('LOGADO EFFECT', dataClient);

			let paramCurrentOrder = Number(query.get('order'));
			let paramCurrentModeview = Number(query.get('modeview'));
			let paramCurrentFilterState = query.get('state');
			let paramCurrentFilterCities = query.get('cities');
			let paramCurrentFilterName = query.get('name');
			let paramCurrentFilterEmail = query.get('email');
			let paramCurrentFilterId = query.get('id');
			let paramCurrentFilterStatus1 = query.get('status1');
			let paramCurrentFilterStatus2 = query.get('status2');
			let paramCurrentFilterStatus3 = query.get('status3');
			let paramCurrentFilterStatus4 = query.get('status4');
			let paramCurrentFilterStatus5 = query.get('status5');

			let paramCurrentFilterDepartment1 = query.get('department1');
			let paramCurrentFilterDepartment2 = query.get('department2');
			let paramCurrentFilterDepartment3 = query.get('department3');
			let paramCurrentFilterDepartment4 = query.get('department4');
			let paramCurrentFilterDepartment5 = query.get('department5');
			let paramCurrentFilterDepartment6 = query.get('department6');
			let paramCurrentFilterDepartment7 = query.get('department7');
			let paramCurrentFilterDepartment8 = query.get('department8');
			// let paramCurrentFilterDepartment9 = query.get('department9');
			// let paramCurrentFilterDepartment10 = query.get('department10');
			// let paramCurrentFilterDepartment11 = query.get('department11');
			// let paramCurrentFilterDepartment12 = query.get('department12');
			// let paramCurrentFilterDepartment13 = query.get('department13');
			// let paramCurrentFilterDepartment14 = query.get('department14');
			// let paramCurrentFilterDepartment15 = query.get('department15');

			let paramCurrentFilterSpace1 = query.get('space1');
			let paramCurrentFilterSpace2 = query.get('space2');
			let paramCurrentFilterSpace3 = query.get('space3');
			let paramCurrentFilterSpace4 = query.get('space4');
			let paramCurrentFilterSpace5 = query.get('space5');

			let paramCurrentFilterSpaceId1 = query.get('spaceid1');
			let paramCurrentFilterSpaceId2 = query.get('spaceid2');
			let paramCurrentFilterSpaceId3 = query.get('spaceid3');
			let paramCurrentFilterSpaceId4 = query.get('spaceid4');
			let paramCurrentFilterSpaceId5 = query.get('spaceid5');

			if (
				(paramCurrentFilterEmail !== null && paramCurrentFilterEmail !== '') ||
				(paramCurrentFilterName !== null && paramCurrentFilterName !== '') ||
				(paramCurrentFilterId !== null && paramCurrentFilterId !== '') ||
				(paramCurrentFilterStatus1 !== null && paramCurrentFilterStatus1 !== '') ||
				(paramCurrentFilterStatus2 !== null && paramCurrentFilterStatus2 !== '') ||
				(paramCurrentFilterStatus3 !== null && paramCurrentFilterStatus3 !== '') ||
				(paramCurrentFilterStatus4 !== null && paramCurrentFilterStatus4 !== '') ||
				(paramCurrentFilterStatus5 !== null && paramCurrentFilterStatus5 !== '') ||
				(paramCurrentFilterCities !== null && paramCurrentFilterCities !== '') ||
				(paramCurrentFilterState !== null && paramCurrentFilterState !== '')
			) {
				setHasSomeFilter(true);
			} else {
				setHasSomeFilter(false);
			}

			// if (paramCurrentPage) {
			// } else {
			// 	history.push(
			// 		`/dashboard?page=1&order=1&modeview=1&department1=true&department2=true&department3=true&department4=true&department5=true&department6=true&department7=true&department8=true&department9=true&department10=true&department11=true&department12=true&department13=true&department14=true&department=true`,
			// 	);
			// }
			setCurrentPage(paramCurrentPage);
			setCurrentOrder(paramCurrentOrder);
			setCurrentModeview(paramCurrentModeview);
			setCurrentCalendarday(paramCurrentCalendarday);
			setCurrentFilter({
				...currentFilter,
				state: paramCurrentFilterState ? paramCurrentFilterState : '',
				cities: paramCurrentFilterCities ? paramCurrentFilterCities : '',
				name: paramCurrentFilterName ? paramCurrentFilterName : '',
				email: paramCurrentFilterEmail ? paramCurrentFilterEmail : '',
				id: paramCurrentFilterId ? paramCurrentFilterId : '',
				status_1: paramCurrentFilterStatus1 === 'true' ? true : false,
				status_2: paramCurrentFilterStatus2 === 'false' ? false : true,
				status_3: paramCurrentFilterStatus3 === 'false' ? false : true,
				status_4: paramCurrentFilterStatus4 === 'false' ? false : true,
				status_5: paramCurrentFilterStatus5 === 'true' ? true : false,

				department_1: paramCurrentFilterDepartment1 === 'false' ? false : true,
				department_2: paramCurrentFilterDepartment2 === 'false' ? false : true,
				department_3: paramCurrentFilterDepartment3 === 'false' ? false : true,
				department_4: paramCurrentFilterDepartment4 === 'false' ? false : true,
				department_5: paramCurrentFilterDepartment5 === 'false' ? false : true,
				department_6: paramCurrentFilterDepartment6 === 'false' ? false : true,
				department_7: paramCurrentFilterDepartment7 === 'false' ? false : true,
				department_8: paramCurrentFilterDepartment8 === 'false' ? false : true,
				// department_9: paramCurrentFilterDepartment9 === 'false' ? false : true,
				// department_10: paramCurrentFilterDepartment10 === 'false' ? false : true,
				// department_11: paramCurrentFilterDepartment11 === 'false' ? false : true,
				// department_12: paramCurrentFilterDepartment12 === 'false' ? false : true,
				// department_13: paramCurrentFilterDepartment13 === 'false' ? false : true,
				// department_14: paramCurrentFilterDepartment14 === 'false' ? false : true,
				// department_15: paramCurrentFilterDepartment15 === 'false' ? false : true,

				space_1: paramCurrentFilterSpace1 === 'false' ? false : true,
				space_2: paramCurrentFilterSpace2 === 'false' ? false : true,
				space_3: paramCurrentFilterSpace3 === 'false' ? false : true,
				space_4: paramCurrentFilterSpace4 === 'false' ? false : true,
				space_5: paramCurrentFilterSpace5 === 'false' ? false : true,

				space_id_1: dataClient.spaces[0] ? dataClient.spaces[0].id : 0,
				space_id_2: dataClient.spaces[1] ? dataClient.spaces[1].id : 0,
				space_id_3: dataClient.spaces[2] ? dataClient.spaces[2].id : 0,
				space_id_4: dataClient.spaces[3] ? dataClient.spaces[3].id : 0,
				space_id_5: dataClient.spaces[4] ? dataClient.spaces[4].id : 0,
			});

			// console.log('ZELDA', dataClient.spaces, paramCurrentFilterSpaceId1);
			setUpdateSearch(new Date().getTime());

			const d = new Date();
			const dformat =
				[d.getDate(), d.getMonth() + 1, d.getFullYear()].join('/') +
				' ' +
				[d.getHours(), d.getMinutes(), d.getSeconds()].join(':');

			// setLastTimestampResult(`${dformat}`);
		} else {
			console.log('NÃO FUNCIONA NADA', dataClient);
		}
	}, [history.location.search, dataClient]);

	// const [formFieldsSearch, setFormFieldsSearch] = useState([
	// 	{
	// 		name: 'page',
	// 		value: currentPage,
	// 		error: '',
	// 		type: 'text',
	// 	},
	// 	{
	// 		name: 'order',
	// 		value: currentOrder,
	// 		error: '',
	// 		type: 'text',
	// 	},
	// ]);

	const [modalState, setModalState] = useState({
		MY_MODAL: { status: false },
		SECOND_MODAL: { status: false },
		MODAL_VIEW_POST: { status: false },
	});

	const openModal = (modalName, leadId) => {
		setModalState({ ...modalState, [modalName]: { status: true } });

		if (modalName === 'MY_MODAL') {
			if (leadId) getLeadDataModal(leadId);
		}
	};

	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	const getLeadDataModal = async (leadId) => {
		setLeadCurrentData(null);
		getLeadData(leadId).then((response) => {
			if (Number(response.data.lead.status) === 1) {
				setLeadCurrentData({
					name: response.data.lead.name,
					id: response.data.lead.id,
					rate: response.data.lead.rate,
					comment: response.data.lead.comment,
					resume: response.data.lead.resume,
					whatsapp: response.data.lead.whatsapp,
					email: response.data.lead.email,
					timestamp: response.data.lead.timestamp,
					department: response.data.lead.department,
					state: response.data.lead.state,
					city: response.data.lead.city,
				});
			}
		});
	};

	const closeModal = (modalName) => {
		const documentBody = document.querySelector('body');
		if (documentBody !== null) {
			documentBody.className = '';
		}
		setModalState({ ...modalState, [modalName]: { status: false } });
		setLeadCurrentData(null);
	};

	const updatePagination = (page) => {
		// console.log('update page', page);
		history.push(
			`/dashboard?page=${page}&calendarday=${currentCalendarday}&order=${currentOrder}&modeview=${currentModeview}&state=${currentFilter.state}&cities=${currentFilter.cities}&name=${currentFilter.name}&email=${currentFilter.email}&id=${currentFilter.id}&status1=${currentFilter.status_1}&status2=${currentFilter.status_2}&status3=${currentFilter.status_3}&status4=${currentFilter.status_4}&status5=${currentFilter.status_5}&department1=${currentFilter.department_1}&department2=${currentFilter.department_2}&department3=${currentFilter.department_3}&department4=${currentFilter.department_4}&department5=${currentFilter.department_5}&department6=${currentFilter.department_6}&department7=${currentFilter.department_7}&department8=${currentFilter.department_8}&space1=${currentFilter.space_1}&space2=${currentFilter.space_2}&space3=${currentFilter.space_3}&space4=${currentFilter.space_4}&space5=${currentFilter.space_5}&spaceid1=${currentFilter.space_id_1}&spaceid2=${currentFilter.space_id_2}&spaceid3=${currentFilter.space_id_3}&spaceid4=${currentFilter.space_id_4}&spaceid5=${currentFilter.space_id_5}`,
		);
	};

	const updateCalendarday = (monthday) => {
		console.log('update month', monthday);
		history.push(
			`/dashboard?page=${currentPage}&calendarday=${monthday}&order=${currentOrder}&modeview=${currentModeview}&state=${currentFilter.state}&cities=${currentFilter.cities}&name=${currentFilter.name}&email=${currentFilter.email}&id=${currentFilter.id}&status1=${currentFilter.status_1}&status2=${currentFilter.status_2}&status3=${currentFilter.status_3}&status4=${currentFilter.status_4}&status5=${currentFilter.status_5}&department1=${currentFilter.department_1}&department2=${currentFilter.department_2}&department3=${currentFilter.department_3}&department4=${currentFilter.department_4}&department5=${currentFilter.department_5}&department6=${currentFilter.department_6}&department7=${currentFilter.department_7}&department8=${currentFilter.department_8}&space1=${currentFilter.space_1}&space2=${currentFilter.space_2}&space3=${currentFilter.space_3}&space4=${currentFilter.space_4}&space5=${currentFilter.space_5}&spaceid1=${currentFilter.space_id_1}&spaceid2=${currentFilter.space_id_2}&spaceid3=${currentFilter.space_id_3}&spaceid4=${currentFilter.space_id_4}&spaceid5=${currentFilter.space_id_5}`,
		);
	};

	const loadingDataClient = async () => {
		await getClientData().then((responseData) => {
			if (responseData.data.status === 1) {
				console.log('SHOUDL WORK', responseData.data.client);
				setDataClient(responseData.data.client);
			} else {
			}
		});
	};

	//
	const handleOrder = (e) => {
		setCurrentOrder(e.target.value);
		// console.log('filter alter', e.target.value, formFieldsSearch);
		history.push(
			`/dashboard?page=${currentPage}&calendarday=${currentCalendarday}&order=${e.target.value}&modeview=${currentModeview}&state=${currentFilter.state}&cities=${currentFilter.cities}&name=${currentFilter.name}&email=${currentFilter.email}&id=${currentFilter.id}&status1=${currentFilter.status_1}&status2=${currentFilter.status_2}&status3=${currentFilter.status_3}&status4=${currentFilter.status_4}&status5=${currentFilter.status_5}&department1=${currentFilter.department_1}&department2=${currentFilter.department_2}&department3=${currentFilter.department_3}&department4=${currentFilter.department_4}&department5=${currentFilter.department_5}&department6=${currentFilter.department_6}&department7=${currentFilter.department_7}&department8=${currentFilter.department_8}&space1=${currentFilter.space_1}&space2=${currentFilter.space_2}&space3=${currentFilter.space_3}&space4=${currentFilter.space_4}&space5=${currentFilter.space_5}&spaceid1=${currentFilter.space_id_1}&spaceid2=${currentFilter.space_id_2}&spaceid3=${currentFilter.space_id_3}&spaceid4=${currentFilter.space_id_4}&spaceid5=${currentFilter.space_id_5}`,
		);
	};

	//
	const handleMode = (modeId) => {
		if (modeId !== currentModeview) {
			setCurrentModeview(modeId);
			// console.log('filter alter', e.target.value, formFieldsSearch);
			history.push(
				`/dashboard?page=${currentPage}&calendarday=${currentCalendarday}&order=${currentOrder}&modeview=${modeId}&state=${currentFilter.state}&cities=${currentFilter.cities}&name=${currentFilter.name}&email=${currentFilter.email}&id=${currentFilter.id}&status1=${currentFilter.status_1}&status2=${currentFilter.status_2}&status3=${currentFilter.status_3}&status4=${currentFilter.status_4}&status5=${currentFilter.status_5}&department1=${currentFilter.department_1}&department2=${currentFilter.department_2}&department3=${currentFilter.department_3}&department4=${currentFilter.department_4}&department5=${currentFilter.department_5}&department6=${currentFilter.department_6}&department7=${currentFilter.department_7}&department8=${currentFilter.department_8}&space1=${currentFilter.space_1}&space2=${currentFilter.space_2}&space3=${currentFilter.space_3}&space4=${currentFilter.space_4}&space5=${currentFilter.space_5}&spaceid1=${currentFilter.space_id_1}&spaceid2=${currentFilter.space_id_2}&spaceid3=${currentFilter.space_id_3}&spaceid4=${currentFilter.space_id_4}&spaceid5=${currentFilter.space_id_5}`,
			);
		}
	};

	//
	// const handleDownloadCSV = async (e) => {
	// 	e.preventDefault();

	// 	await getClientCSV(
	// 		`page=1&order=${currentOrder}&modeview=${currentModeview}&state=${currentFilter.state}&cities=${currentFilter.cities}&name=${currentFilter.name}&email=${currentFilter.email}&id=${currentFilter.id}`,
	// 	).then((responseData: any) => {
	// 		if (responseData.data.status === 1) {
	// 		} else {
	// 		}
	// 	});
	// };

	//
	const handleFilter = (e) => {
		e.preventDefault();
		setIsFilterOpened(false);
		closeModal('MY_MODAL');
		history.push(
			`/dashboard?page=1&calendarday=${currentCalendarday}&order=${currentOrder}&modeview=${currentModeview}&state=${currentFilter.state}&cities=${currentFilter.cities}&name=${currentFilter.name}&email=${currentFilter.email}&id=${currentFilter.id}&status1=${currentFilter.status_1}&status2=${currentFilter.status_2}&status3=${currentFilter.status_3}&status4=${currentFilter.status_4}&status5=${currentFilter.status_5}&department1=${currentFilter.department_1}&department2=${currentFilter.department_2}&department3=${currentFilter.department_3}&department4=${currentFilter.department_4}&department5=${currentFilter.department_5}&department6=${currentFilter.department_6}&department7=${currentFilter.department_7}&department8=${currentFilter.department_8}&space1=${currentFilter.space_1}&space2=${currentFilter.space_2}&space3=${currentFilter.space_3}&space4=${currentFilter.space_4}&space5=${currentFilter.space_5}&spaceid1=${currentFilter.space_id_1}&spaceid2=${currentFilter.space_id_2}&spaceid3=${currentFilter.space_id_3}&spaceid4=${currentFilter.space_id_4}&spaceid5=${currentFilter.space_id_5}`,
		);
	};

	//
	const handleResetFilters = () => {
		closeModal('MY_MODAL');
		history.push(
			`/dashboard?page=1&calendarday=${moment().format(
				'YYYY-MM-DD',
			)}&order=1&modeview=${currentModeview}&department1=true&department2=true&department3=true&department4=true&department5=true&department6=true&department7=true&department8=true&space1=true&space2=true&space3=true&space4=true&space5=true`,
		);
	};

	// open filter

	useEffect(() => {
		const localStorageAuth = getHasLocalStorageAuth();
		setFileToken({
			token: localStorageAuth.data.token,
			email: localStorageAuth.data.email,
			timestamp: localStorageAuth.data.timestamp,
		});
		loadingDataClient();
	}, []);

	useEffect(() => {
		handleWindowSizeChange();
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	if (dataClient === null) {
		return <Placeholder />;
	}

	return (
		<>
			<Helmet>
				<title>Planner: Hub</title>
				<meta name="description" content="BP Planner" />
			</Helmet>

			<ModalCustom
				status={modalState.MY_MODAL.status}
				closeModal={closeModal}
				modal="MY_MODAL"
				className=" justify-between absolute right-0 top-0 m-0 h-full max-w-[600px]"
			>
				<FilterModal
					handleFilter={handleFilter}
					currentFilter={currentFilter}
					setCurrentFilter={setCurrentFilter}
					closeModal={closeModal}
					dataClient={dataClient}
					hasSomeFilter={hasSomeFilter}
					handleResetFilters={handleResetFilters}
				/>
			</ModalCustom>

			<ModalCustom
				status={modalState.MODAL_VIEW_POST.status}
				closeModal={closeModal}
				modal="MODAL_VIEW_POST"
				className=" justify-between max-w-xl absolute right-0 top-0 m-0 h-full"
			>
				{/* <p>MODAL_VIEW_POST {formFieldsPostIdLocal}</p> */}
				<PostView
					closeModal={closeModal}
					dataPostId={formFieldsPostIdLocal}
					status={modalState.MODAL_VIEW_POST.status}
				/>
			</ModalCustom>

			<ModalCustom
				status={modalState.SECOND_MODAL.status}
				closeModal={closeModal}
				modal="SECOND_MODAL"
				className=" justify-between max-w-xl absolute right-0 top-0 m-0 h-full"
			>
				<LeadAddForm closeModal={closeModal} spaces={dataClient.spaces} />
			</ModalCustom>

			<div className="min-h-screen flex justify-between flex-col">
				<div>
					<HeaderTop dataClient={dataClient} />

					<div className="xl:container mx-auto my-2 md:my-5 px-2 md:px-5 3xl:px-0 ">
						{currentModeview === 2 && hasAllQueries && (
							<CalendarView
								lastUpdate={updateSearch}
								params={`${history.location.search}`}
								currentMonth={currentCalendarday}
								callbackChangeCalendarday={updateCalendarday}
								setFormFieldsPostIdLocal={setFormFieldsPostIdLocal}
								openModalLead={() => openModal('MODAL_VIEW_POST')}
								currentModeview={currentModeview}
								currentOrder={currentOrder}
								hasSomeFilter={hasSomeFilter}
								setIsFilterOpened={setIsFilterOpened}
								isFilterOpened={isFilterOpened}
								handleMode={handleMode}
								openModal={openModal}
								handleOrder={handleOrder}
							/>
						)}

						{currentModeview === 1 && hasAllQueries && (
							<>
								<div className="flex justify-between items-center  mb-1 md:mb-3 my-1 md:my-5">
									<div className="flex justify-between items-center space-x-3">
										<h2 className="font-bold text-md md:text-2xl text-[#FD2E91]">
											{currentModeview === 1 ? 'Postagens' : 'Calendário'}
										</h2>
									</div>

									<FilterButtons
										currentModeview={currentModeview}
										currentOrder={currentOrder}
										hasSomeFilter={hasSomeFilter}
										setIsFilterOpened={setIsFilterOpened}
										isFilterOpened={isFilterOpened}
										handleMode={handleMode}
										openModal={openModal}
										handleOrder={handleOrder}
									/>
								</div>

								<PaginationJson
									data={`${END_POINT_BASE}/posts/posts`}
									params={`${history.location.search}`}
									setData={setMessages}
									lastUpdate={updateSearch}
									pathData="posts"
									saveLocalJson={false}
									callbackChangePage={updatePagination}
									currentPage={currentPage}
									perPage={30}
								>
									{messages ? (
										messages.length > 0 ? (
											<div className="my-2 md:my-5 overflow-x-auto">
												<table className="   rounded-lg overflow-hidden  w-full tr-even:bg-grey-100">
													<thead>
														<tr className="bg-[#F6F6F6]  rounded-lg overflow-hidden ">
															<th className="text-sm text-left pl-4 py-3">Postagem</th>
															<th className="text-sm text-left pl-4 py-3">Canais</th>
															<th className="text-sm text-left pl-4 py-3">Qualidade</th>
															<th className="text-sm text-left pl-4 py-3">Agendamento</th>
															<th className="text-sm text-left pl-4 py-3">Space</th>
														</tr>
													</thead>
													<tbody>
														{messages.map((post, i) => {
															return (
																<tr
																	className={`odd:bg-white even:bg-[#F6F6F6] `}
																	key={`post-${post.id}`}
																>
																	<td className=" text-sm py-1 px-4  ">
																		<div className="py-1">
																			<div className="flex items-center">
																				<button
																					type="button"
																					className="block p-2 aspect-square h-28 mr-5"
																					onClick={() => {
																						setFormFieldsPostIdLocal(post.id);
																						openModal('MODAL_VIEW_POST');
																					}}
																				>
																					<img
																						src={post.thumb}
																						alt=""
																						className="rounded-lg overflow-hidden"
																					/>
																				</button>
																				<div className="flex flex-col space-y-1">
																					<div>
																						<p className="text-xs text-gray-400 bg-gray-200 px-1 rounded-md inline-block">
																							#{post.id}
																						</p>
																						<p className="text-md font-bold break-all">
																							{post.title}
																						</p>
																						<p className="text-clip break-all">{post.copyResume}</p>
																					</div>

																					<p className="text-[#4131FA] inline-block">
																						<Link to={`/post/${post.id}`}>Editar</Link>
																					</p>
																				</div>
																			</div>
																		</div>
																	</td>
																	<td className=" text-sm py-1 px-4  w-28">
																		<div className="py-1 w-28">
																			<ul className="flex flex-wrap -ml-1">
																				{post.channels.map((channel, index) => {
																					return (
																						<IconChannel key={`channel-${index}`} icon={channel} />
																					);
																				})}
																			</ul>
																		</div>
																	</td>
																	{/* <td className=" text-sm py-1 px-2">
																</td> */}
																	<td className=" text-sm py-1 px-4  w-52">
																		<div className="py-1 w-52">
																			{post.rate > 0 ? (
																				<RateStar rate={post.rate} />
																			) : (
																				'"Sem classificação"'
																			)}
																			<div className="my-2">
																				{post.campaignsList.map((schedule, index) => {
																					return (
																						<p className="text-xs" key={`${schedule.id}-${index}`}>
																							<i className="bi bi-star-fill text-xs" />
																							{schedule.rate}/5 | Lea: {schedule.leads} | Alc:{' '}
																							{schedule.visualization} | CTL: R$ {schedule.ctl}
																						</p>
																					);
																				})}
																			</div>
																		</div>
																	</td>
																	<td className=" text-sm py-1 px-4  w-40">
																		<div className="w-40 space-y-2">
																			<div
																				className={`${
																					post.status === '1'
																						? 'bg-blue-400 text-white'
																						: post.status === '2'
																						? 'bg-gray-300 text-white'
																						: post.status === '3'
																						? 'bg-orange-600 text-white'
																						: post.status === '4'
																						? 'bg-lime-500 text-white'
																						: 'bg-gray-600 text-white'
																				}  rounded-md px-2  text-xs  py-1 inline-block`}
																			>
																				{rateTxt[post.status - 1]}
																			</div>
																			<div>
																				<p className="text-md">Status: {post.publish}</p>
																				<p className="text-md">Dias ativos: {post.daysCampaign}</p>
																			</div>
																		</div>
																	</td>
																	<td className=" text-sm py-1 px-4  w-32">
																		<div className="w-32">{post.spaceName}</div>
																	</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											</div>
										) : (
											<p>Nada encontrado</p>
										)
									) : (
										<Placeholder title="Buscando..." />
									)}
								</PaginationJson>
							</>
						)}
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};

export default PageClientHub;

export const IconChannel = ({ icon }) => {
	const channelArr = {
		Facebook: 'bi-facebook',
		Instagram: 'bi-instagram',
		Newsletter: 'bi-envelope-paper',
		'Google Ads': 'bi-google',
		Whatsapp: 'bi-whatsapp',
		Twitter: 'bi-twitter',
		Linkedin: 'bi-linkedin',
		Base: 'bi-folder',
	};
	return (
		<div className=" w-5 h-5 aspect-square flex justify-center items-center m-1">
			<i className={` ${channelArr[icon]} text-lg `} />
		</div>
	);
};
