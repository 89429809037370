/* eslint-disable */
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { validateForm } from '../FormValidation';
import Textarea from 'react-expanding-textarea';
import { addSchedule } from '../../Api/clientScheduleHandle';
import { convertTimestampTo } from '../../helpers/utils';

const ScheduleAdd = ({ closeModal, loadDataSchedules, postId, handlePostIsUpdated }) => {
	const [sending, setSending] = useState(false);

	const [formFields, setFormFields] = useState([
		{
			// 0 postid
			name: 'post_id',
			value: postId,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 1000,
			minLength: 1,
		},
		{
			// 1 rate
			name: 'rate',
			value: 0,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 1000,
			minLength: 0,
		},
		{
			// 2 start day
			name: 'timestamp_start_day',
			value: convertTimestampTo(new Date(), 'BRAZIL_SIMPLE'),
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 8,
			minLength: 8,
		},
		{
			// 3 start hour
			name: 'timestamp_start_hour',
			value: '06:00',
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 5,
			minLength: 5,
		},
		{
			// 4 end
			name: 'timestamp_end_day',
			value: convertTimestampTo(new Date(), 'BRAZIL_SIMPLE'),
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 8,
			minLength: 8,
		},
		{
			// 5 end
			name: 'timestamp_end_hour',
			value: '06:00',
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 5,
			minLength: 5,
		},
		{
			// 6 note
			name: 'note',
			value: '',
			error: '',
			type: 'text',
			maxLength: 2000,
		},
		{
			// 7 investment
			name: 'investment',
			value: '0,00',
			error: '',
			type: 'text',
			maxLength: 2000,
		},
		{
			// 8 ctr meta
			name: 'ctr_meta',
			value: 0,
			error: '',
			type: 'text',
			maxLength: 6,
		},
		{
			// 9 ctl meta
			name: 'ctl_meta',
			value: '0,00',
			error: '',
			type: 'text',
			maxLength: 6,
		},
	]);

	// handle input change
	const handleBlur = (e, mask = '') => {
		const isCheckBox = e.target.type === 'checkbox';
		let currentValue = e.target.value;

		if (mask === 'MONEY') {
			const _formatCurrency = function (amount) {
				console.log('USSO1', amount);
				const number = amount.replace(/\,/g, '.');
				console.log('USSO2', number);
				return parseFloat(number)
					.toFixed(2)
					.replace(/(\d)(?=(\d{3})+\,)/g, '$1');
			};

			currentValue = _formatCurrency(currentValue);
			currentValue = currentValue === 'NaN' ? '0,00' : currentValue;
			currentValue = currentValue.replace(/\./g, ',');
		}

		if (mask === 'PERCENTAGE') {
			const _formatCurrency = function (amount) {
				return parseFloat(amount)
					.toFixed(2)
					.replace(/(\d)(?=(\d{3})+\,)/g, '$1');
			};
			currentValue = _formatCurrency(currentValue);
			currentValue = currentValue === 'NaN' ? 0.0 : currentValue;
		}

		setFormFields(
			formFields.map((field) => {
				if (field.name === e.target.name) {
					return {
						...field,
						value: isCheckBox ? e.target.checked : currentValue,
						error: '',
					};
				}
				return { ...field };
			}),
		);
	};

	// handle input change
	const handleChange = (e, mask = '') => {
		const isCheckBox = e.target.type === 'checkbox';
		let currentValue = e.target.value;

		// OPTIONAL: clean spaces
		if (
			e.target.name === 'email' ||
			e.target.name === 'password' ||
			e.target.name === 'passwordConfirm' ||
			e.target.name === 'phone'
		) {
			currentValue = currentValue.replace(/\s/g, '');
			currentValue = currentValue.toLowerCase();
		}

		if (mask === 'DATE') {
			currentValue = currentValue.replace(/\D/g, ''); //Remove tudo o que não é dígito
			currentValue = currentValue.replace(/(\d{2})(\d)/, '$1/$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
			currentValue = currentValue.replace(/(\d{2})(\d)/, '$1/$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
		}

		if (mask === 'HOUR') {
			currentValue = currentValue.replace(/\D/g, ''); //Remove tudo o que não é dígito
			currentValue = currentValue.replace(/(\d{2})(\d)/, '$1:$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
		}

		if (mask === 'PERCENTAGE') {
			currentValue = currentValue.replace(/[^0-9.-]/g, '');
		}

		if (mask === 'MONEY') {
			currentValue = currentValue.replace(/[^0-9,-]/g, '');
		}

		setFormFields(
			formFields.map((field) => {
				if (field.name === e.target.name) {
					return {
						...field,
						value: isCheckBox ? e.target.checked : currentValue,
						error: '',
					};
				}
				return { ...field };
			}),
		);
	};

	// validation form
	const validationForm = () => {
		let hasNoErrors = true;
		const inputRequired = validateForm(formFields, setFormFields);
		if (hasNoErrors) {
			hasNoErrors = inputRequired.hasPassed;
		}
		return hasNoErrors;
	};

	// conclusion
	const handleSubmitAll = async (e) => {
		if (!sending) {
			e.preventDefault();
			const isValid = validationForm();

			if (isValid) {
				const toastId = toast.loading('Salvando...');
				setSending(true);
				addSchedule(
					postId,
					formFields[1].value,
					formFields[2].value,
					formFields[3].value,
					formFields[4].value,
					formFields[5].value,
					formFields[6].value,
					formFields[7].value,
					formFields[8].value,
					formFields[9].value,
				)
					.then((response) => {
						setSending(false);
						loadDataSchedules();
						closeModal('MODAL_ADD_POST_SCHEDULE');
						setFormFields([
							{ ...formFields[0] },
							{ ...formFields[1], value: 0 },
							{ ...formFields[2], value: '' },
							{ ...formFields[3], value: '' },
							{ ...formFields[4], value: '' },
							{ ...formFields[5], value: '' },
							{ ...formFields[6], value: '' },
							{ ...formFields[7], value: '' },
							{ ...formFields[8], value: '' },
							{ ...formFields[9], value: '' },
						]);
						handlePostIsUpdated();
						toast.success('Agendamento adicionado', {
							position: 'bottom-left',
							id: toastId,
						});
					})
					.catch((error) => {
						console.log('Error', error);
					});
			} else {
				toast.error('error', {
					id: toastId,
				});
			}
		}
	};

	return (
		<form onSubmit={handleSubmitAll} className="flex flex-col items-stretch h-full">
			<div className="px-5 h-[50px] flex-shrink-0  border-gray-200 flex items-center justify-between">
				<h2 className="text-lg font-bold text-[#FD2E91]">Cadastrar programação</h2>
				<button
					onClick={() => closeModal('MODAL_ADD_POST_SCHEDULE')}
					className="text-blue-500 hover:underline"
				>
					<i className="bi-x text-4xl" />
				</button>
			</div>
			<div className="basis-full h-full bg-white overflow-y-auto px-3 md:px-5 py-3 md:py-5">
				<div className="flex space-x-5">
					<div className="w-1/2 flex ">
						<label htmlFor={formFields[2].name} className="mb-3 md:mb-2 block">
							<span className="block font-semibold mb-1 text-sm">Início - dia:</span>
							<input
								type={formFields[2].type}
								name={formFields[2].name}
								maxLength={formFields[2].maxLength}
								placeholder="dd/mm/aa"
								value={formFields[2].value}
								id={formFields[2].name}
								onChange={(e) => handleChange(e, 'DATE')}
								className={`w-full py-1 px-3 rounded-l-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
									formFields[2].error.length === 0
										? 'border border-gray-400'
										: 'border border-red-500'
								}`}
							/>
							<span className="text-red-500 text-xs block">{formFields[2].error}</span>
						</label>
						<label htmlFor={formFields[3].name} className="mb-3 md:mb-2 block">
							<span className="block font-semibold mb-1 text-sm">Início - hora:</span>
							<input
								type={formFields[3].type}
								name={formFields[3].name}
								maxLength={formFields[3].maxLength}
								placeholder="hh/mm"
								value={formFields[3].value}
								id={formFields[3].name}
								onChange={(e) => handleChange(e, 'HOUR')}
								className={`w-full py-1 px-3 rounded-r-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
									formFields[3].error.length === 0
										? 'border border-gray-400'
										: 'border border-red-500'
								}`}
							/>
							<span className="text-red-500 text-xs block">{formFields[3].error}</span>
						</label>
					</div>
					<div className="w-1/2 flex">
						<label htmlFor={formFields[4].name} className="mb-3 md:mb-2 block">
							<span className="block font-semibold mb-1 text-sm">Final - dia:</span>
							<input
								type={formFields[4].type}
								name={formFields[4].name}
								maxLength={formFields[4].maxLength}
								placeholder="dd/mm/aa"
								value={formFields[4].value}
								id={formFields[4].name}
								onChange={(e) => handleChange(e, 'DATE')}
								className={`w-full py-1 px-3 rounded-l-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
									formFields[4].error.length === 0
										? 'border border-gray-400'
										: 'border border-red-500'
								}`}
							/>
							<span className="text-red-500 text-xs block">{formFields[4].error}</span>
						</label>
						<label htmlFor={formFields[5].name} className="mb-3 md:mb-2 block">
							<span className="block font-semibold mb-1 text-sm">Final - hora:</span>
							<input
								type={formFields[5].type}
								name={formFields[5].name}
								maxLength={formFields[5].maxLength}
								placeholder="hh:mm"
								value={formFields[5].value}
								id={formFields[5].name}
								onChange={(e) => handleChange(e, 'HOUR')}
								className={`w-full py-1 px-3 rounded-r-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
									formFields[5].error.length === 0
										? 'border border-gray-400'
										: 'border border-red-500'
								}`}
							/>
							<span className="text-red-500 text-xs block">{formFields[5].error}</span>
						</label>
					</div>
				</div>

				<div className="flex space-x-3">
					<label htmlFor={formFields[7].name} className="mb-3 md:mb-2 block w-1/2">
						<span className="block font-semibold mb-1 text-sm">Investimento: (R$)</span>
						<input
							type={formFields[7].type}
							name={formFields[7].name}
							maxLength={formFields[7].maxLength}
							placeholder="0,00"
							value={formFields[7].value}
							id={formFields[7].name}
							onChange={(e) => handleChange(e, 'MONEY')}
							onBlur={(e) => handleBlur(e, 'MONEY')}
							className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
								formFields[7].error.length === 0
									? 'border border-gray-400'
									: 'border border-red-500'
							}`}
						/>
						<span className="text-red-500 text-xs block">{formFields[7].error}</span>
					</label>

					<div className="mb-3 md:mb-2  w-1/2 flex space-x-3">
						<label htmlFor={formFields[8].name} className="mb-3 md:mb-2 block w-1/2">
							<span className="block font-semibold mb-1 text-sm">CTR meta: (%)</span>
							<input
								type={formFields[8].type}
								name={formFields[8].name}
								maxLength={formFields[8].maxLength}
								placeholder="0.00"
								value={formFields[8].value}
								id={formFields[8].name}
								onChange={(e) => handleChange(e, 'PERCENTAGE')}
								onBlur={(e) => handleBlur(e, 'PERCENTAGE')}
								className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
									formFields[8].error.length === 0
										? 'border border-gray-400'
										: 'border border-red-500'
								}`}
							/>
							<span className="text-red-500 text-xs block">{formFields[8].error}</span>
						</label>
						<label htmlFor={formFields[9].name} className="mb-3 md:mb-2 block w-1/2">
							<span className="block font-semibold mb-1 text-sm">CTL meta: (R$)</span>
							<input
								type={formFields[9].type}
								name={formFields[9].name}
								maxLength={formFields[9].maxLength}
								placeholder="0,00"
								value={formFields[9].value}
								id={formFields[9].name}
								onChange={(e) => handleChange(e, 'MONEY')}
								onBlur={(e) => handleBlur(e, 'MONEY')}
								className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
									formFields[9].error.length === 0
										? 'border border-gray-400'
										: 'border border-red-500'
								}`}
							/>
							<span className="text-red-500 text-xs block">{formFields[9].error}</span>
						</label>
					</div>
				</div>

				<label htmlFor={formFields[6].name} className="mb-3 md:mb-2 block">
					<span className="block font-semibold mb-1 text-sm">Anotação:</span>
					<Textarea
						rows={3}
						name={formFields[6].name}
						id={formFields[6].name}
						maxLength={formFields[6].maxLength}
						value={formFields[6].value}
						onChange={(e) => handleChange(e)}
						className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
							formFields[6].error.length === 0 ? 'border border-gray-400' : 'border border-red-500'
						}`}
					/>
					<span>{formFields[6].error}</span>
				</label>
			</div>

			<div className="px-5 h-[70px] flex-shrink-0 border-t border-gray-200 flex items-center justify-end">
				<div className="flex space-x-3 justify-end  ">
					<button
						type="button"
						onClick={() => closeModal('MODAL_ADD_POST_SCHEDULE')}
						className="block px-3 py-2 w-auto rounded-md bg-white hover:bg-gray-50 text-gray-600 hover:text-blue-500 flex-shrink-0"
					>
						Cancelar
					</button>
					{!sending ? (
						<>
							<button
								type="submit"
								className="px-3 py-2 w-auto rounded-md bg-[#4131FA] hover:bg-[#3125BC] text-white shadow flex-shrink-0"
							>
								Adicionar agendamento
							</button>
						</>
					) : (
						<div
							type="button"
							className="bg-[#4131FA] hover:bg-[#3125BC] py-2 px-3 text-white rounded-md pr-5 "
						>
							Salvando <i className="animate-spin absolute bi-arrow-clockwise text-md " />
						</div>
					)}
				</div>
			</div>
		</form>
	);
};

export default ScheduleAdd;
