/* eslint-disable */
interface IProps {
	title?: string;
	className?: string;
}

const Placeholder = ({ title = 'Carregando...', className = '' }: IProps) => {
	return (
		<div className="flex h-screen w-full justify-center items-center">
			<p className={className}>
				<i className="animate-spin absolute bi-dash-circle-dotted text-5xl text-slate-300" />
			</p>
		</div>
	);

	// <p className={className}>{title}</p>
};

export default Placeholder;
