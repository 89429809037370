/* eslint-disable */
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import ModalCustom from '../ModalCustom';

import Textarea from 'react-expanding-textarea';

import { editDataLead } from '../../Api/clientLeadsHandle';
import { addPost, editDataPost } from '../../Api/clientPostsHandle';
import { validateForm, IForm } from '../FormValidation';
import InputFilesLive from '../InputFilesLive/InputFilesLive';
import { getFiles, removeFile, uploadFile } from '../../Api/fileHandle';

import { END_POINT_BASE } from '../../Api/Api';
import ScheduleAdd from '../ScheduleAdd/ScheduleAdd';
import { editDataSchedule } from '../../Api/clientScheduleHandle';
import {
	_formatCurrency,
	_formatPercentage,
	convertMoneyToNumber,
	moneyToString,
} from '../../helpers/utils';

/*
título do post
descrição
ref
anotação

*/

const ScheduleEdit = ({ closeModal, scheduleData, loadDataSchedules, handlePostIsUpdated }) => {
	const history = useHistory();
	const [sending, setSending] = useState(false);
	// const [modalState, setModalState] = useState({
	// 	MODAL_EDIT_POST_FILES: { status: false },
	// 	MODAL_EDIT_POST_SCHEDULE: { status: false },
	// 	MODAL_EDIT_POST_SCHEDULE: { status: false },
	// });
	const [formLoading, setFormLoading] = useState(false);

	// // file
	// const [formEditFieldsFilesLocal, setFormEditFieldsFilesLocal] = useState([
	// 	{ name: 'files', value: [] },
	// ]);
	// const [formEditFieldsFiles, setFormEditFieldsFiles] = useState({
	// 	attachment: { name: 'files', value: '', error: '', files: [], total: 0 },
	// });

	const [loadBarProgress, setLoadBarProgress] = useState({
		current: 0,
		total: 0,
	});

	const [formFields, setFormFields] = useState([
		{
			// 0 shcedule id
			name: 'id',
			value: scheduleData.id,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 1000,
			minLength: 1,
		},
		{
			// 1 postid
			name: 'post_id',
			value: scheduleData.postId,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 1000,
			minLength: 1,
		},
		{
			// 2 rate
			name: 'rate',
			value: scheduleData.rate,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 1000,
			minLength: 0,
		},
		{
			// 3 start day
			name: 'timestamp_start_day',
			value: scheduleData.startDay,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 8,
			minLength: 8,
		},
		{
			// 4 start hour
			name: 'timestamp_start_hour',
			value: scheduleData.startHour,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 5,
			minLength: 5,
		},
		{
			// 5 end
			name: 'timestamp_end_day',
			value: scheduleData.endDay,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 8,
			minLength: 8,
		},
		{
			// 6 end
			name: 'timestamp_end_hour',
			value: scheduleData.endHour,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 5,
			minLength: 5,
		},
		{
			// 7 note
			name: 'note',
			value: '',
			error: '',
			type: 'text',
			maxLength: 2000,
		},
		{
			// 8 ctr
			name: 'ctr',
			value: scheduleData.ctr,
			error: '',
			type: 'text',
			maxLength: 6,
		},
		{
			// 9 ctl
			name: 'ctl',
			value: moneyToString(scheduleData.ctl),
			error: '',
			type: 'text',
			maxLength: 6,
		},
		{
			// 10 ctr
			name: 'ctr_meta',
			value: scheduleData.ctrMeta,
			error: '',
			type: 'text',
			maxLength: 6,
		},
		{
			// 11 ctl
			name: 'ctl_meta',
			value: moneyToString(scheduleData.ctlMeta),
			error: '',
			type: 'text',
			maxLength: 6,
		},
		{
			// 12 investment
			name: 'investment',
			value: moneyToString(scheduleData.investment),
			error: '',
			type: 'text',
			maxLength: 20,
		},
		{
			// 13 leads conversions
			name: 'conversions',
			value: scheduleData.conversions,
			error: '',
			type: 'text',
			maxLength: 10,
		},
		{
			// 14 visualizacoes
			name: 'visualization',
			value: scheduleData.visualization,
			error: '',
			type: 'text',
			maxLength: 10,
		},
		{
			// 15 clicks
			name: 'clicks',
			value: scheduleData.clicks,
			error: '',
			type: 'text',
			maxLength: 10,
		},
		{
			// 16 ctc
			name: 'ctc',
			value: moneyToString(scheduleData.ctc),
			error: '',
			type: 'text',
			maxLength: 10,
		},
	]);

	// handle input change
	const handleBlur = (e, mask = '') => {
		const isCheckBox = e.target.type === 'checkbox';
		let currentValue = e.target.value;

		if (mask === 'MONEY') {
			currentValue = moneyToString(currentValue);
		}

		if (mask === 'PERCENTAGE') {
			currentValue = _formatPercentage(currentValue);
			currentValue = currentValue === 'NaN' ? 0.0 : currentValue;
		}

		setFormFields(
			formFields.map((field) => {
				if (field.name === e.target.name) {
					return {
						...field,
						value: isCheckBox ? e.target.checked : currentValue,
						error: '',
					};
				}
				return { ...field };
			}),
		);
	};

	// handle input change
	const handleChange = (e, mask = '') => {
		const isCheckBox = e.target.type === 'checkbox';
		let currentValue = e.target.value;

		// OPTIONAL: clean spaces
		if (
			e.target.name === 'email' ||
			e.target.name === 'password' ||
			e.target.name === 'passwordConfirm' ||
			e.target.name === 'phone'
		) {
			currentValue = currentValue.replace(/\s/g, '');
			currentValue = currentValue.toLowerCase();
		}

		// investment
		if (e.target.name === 'investment') {
			let newCtc = 0;
			if (Number(formFields[15].value) === 0 || Number(e.target.value) === 0) {
			} else {
				newCtc = Number(_formatCurrency(e.target.value)) / Number(formFields[15].value);

				newCtc = newCtc.toFixed(2).toString();
				newCtc = newCtc.replace(/\./g, ',');
			}
			formFields[16].value = newCtc;

			let newCtl = 0;
			if (Number(formFields[13].value) === 0 || Number(e.target.value) === 0) {
			} else {
				newCtl = Number(_formatCurrency(e.target.value)) / Number(formFields[13].value);
				newCtl = newCtl.toFixed(2).toString();
				newCtl = newCtl.replace(/\./g, ',');
			}
			formFields[9].value = newCtl;
		}

		// ctc
		if (e.target.name === 'clicks') {
			let newCtc = 0;
			if (Number(e.target.value) === 0) {
			} else {
				newCtc = Number(_formatCurrency(formFields[12].value)) / Number(e.target.value);
				newCtc = newCtc.toFixed(2).toString();
				newCtc = newCtc.replace(/\./g, ',');
				console.log('2', newCtc);
			}
			formFields[16].value = newCtc;
		}

		// ctl
		if (e.target.name === 'conversions') {
			console.log('Cufk');
			let newCtl = 0;
			if (Number(e.target.value) === 0) {
			} else {
				newCtl = Number(_formatCurrency(formFields[12].value)) / Number(e.target.value);
				newCtl = newCtl.toFixed(2).toString();
				newCtl = newCtl.replace(/\./g, ',');
				console.log('2', newCtl);
			}
			formFields[9].value = newCtl;
		}

		if (mask === 'DATE') {
			currentValue = currentValue.replace(/\D/g, ''); //Remove tudo o que não é dígito
			currentValue = currentValue.replace(/(\d{2})(\d)/, '$1/$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
			currentValue = currentValue.replace(/(\d{2})(\d)/, '$1/$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
		}

		if (mask === 'HOUR') {
			currentValue = currentValue.replace(/\D/g, ''); //Remove tudo o que não é dígito
			currentValue = currentValue.replace(/(\d{2})(\d)/, '$1:$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
		}

		if (mask === 'PERCENTAGE') {
			currentValue = currentValue.replace(/[^0-9.-]/g, '');
		}

		if (mask === 'MONEY') {
			// currentValue = currentValue.replace(/[^0-9,-]/g, '');
		}

		setFormFields(
			formFields.map((field) => {
				if (field.name === e.target.name) {
					return {
						...field,
						value: isCheckBox ? e.target.checked : currentValue,
						error: '',
					};
				}
				return { ...field };
			}),
		);
	};

	// validation form
	const validationForm = () => {
		let hasNoErrors = true;
		const inputRequired = validateForm(formFields, setFormFields);
		if (hasNoErrors) {
			hasNoErrors = inputRequired.hasPassed;
		}
		return hasNoErrors;
	};

	// conclusion
	const handleSubmitAll = async (e) => {
		if (!sending) {
			e.preventDefault();
			const isValid = validationForm();

			if (isValid) {
				const toastId = toast.loading('Salvando...');
				setSending(true);
				editDataSchedule(
					formFields[0].value,
					formFields[1].value,
					formFields[2].value,
					formFields[3].value,
					formFields[4].value,
					formFields[5].value,
					formFields[6].value,
					formFields[7].value,
					formFields[8].value,
					formFields[9].value,
					formFields[10].value,
					formFields[11].value,
					formFields[12].value,
					formFields[13].value,
					formFields[14].value,
					formFields[15].value,
					formFields[16].value,
				)
					.then((response) => {
						console.log('gna', response);
						setSending(false);
						loadDataSchedules();
						handlePostIsUpdated();
						toast.success('Agendamento editado', {
							position: 'bottom-left',
							id: toastId,
						});
						closeModal('MODAL_EDIT_POST_SCHEDULE');
						// loadDataFiles();
					})
					.catch((error) => {
						console.log('Error', error);
					});
			} else {
				alert('algum esta errado');
			}
		}
	};

	useEffect(() => {
		setFormFields([
			{ ...formFields[0], value: scheduleData.id },
			{ ...formFields[1], value: scheduleData.postId },
			{ ...formFields[2], value: scheduleData.rate },
			{ ...formFields[3], value: scheduleData.startDay },
			{ ...formFields[4], value: scheduleData.startHour },
			{ ...formFields[5], value: scheduleData.endDay },
			{ ...formFields[6], value: scheduleData.endHour },
			{ ...formFields[7], value: scheduleData.note },
			{ ...formFields[8], value: scheduleData.ctr },
			{ ...formFields[9], value: moneyToString(scheduleData.ctl) },
			{ ...formFields[10], value: scheduleData.ctrMeta },
			{ ...formFields[11], value: moneyToString(scheduleData.ctlMeta) },
			{ ...formFields[12], value: moneyToString(scheduleData.investment) },
			{ ...formFields[13], value: scheduleData.conversions },
			{ ...formFields[14], value: scheduleData.visualization },
			{ ...formFields[15], value: scheduleData.clicks },
			{ ...formFields[16], value: moneyToString(scheduleData.ctc) },
		]);
	}, [scheduleData]);

	return (
		<form onSubmit={handleSubmitAll} className="flex flex-col items-stretch h-full">
			<div className="px-5 h-[50px] flex-shrink-0  border-gray-200 flex items-center justify-between">
				<h2 className="text-lg font-bold text-[#FD2E91]">Editar programação</h2>
				<button
					type="button"
					onClick={() => closeModal('MODAL_EDIT_POST_SCHEDULE')}
					className="text-blue-500 hover:underline"
				>
					<i className="bi-x text-4xl" />
				</button>
			</div>
			<div className="basis-full h-full bg-white overflow-y-auto px-3 md:px-5 py-3 md:py-5">
				<div className="flex space-x-5">
					<div className="w-1/2">
						<div className="flex space-x-3 mt-1 mb-5 text-center">
							<div className="border-2 border-gray-100 rounded-lg w-1/3 py-2 px-2">
								<span className="block font-semibold text-sm">INVESTIMENTO</span>
								<p className="text-xs text-gray-400">Valor investido</p>
								<p className="text-lg mt-2 text-[#15d04d] font-bold">
									<span className="text-xs font-normal">R$</span> {formFields[12].value}
								</p>
							</div>
							<div className="border-2 border-gray-100 rounded-lg w-1/3 py-2 px-2">
								<span className="block font-semibold text-sm">CTL</span>
								<p className="text-xs text-gray-400">Custo por lead</p>
								<p className="text-lg mt-2 text-[#15d04d] font-bold">
									<span className="text-xs font-normal">R$</span> {formFields[9].value}
								</p>
							</div>
							<div className="border-2 border-gray-100 rounded-lg w-1/3 py-2 px-2">
								<span className="block font-semibold mb-1 text-sm">CTC</span>
								<p className="text-xs text-gray-400">Custo por click</p>
								<p className="text-lg mt-2 text-[#15d04d] font-bold">
									<span className="text-xs font-normal">R$</span> {formFields[16].value}
								</p>
							</div>
						</div>
						<div className="my-5">
							<h2 className="text-lg font-bold mb-3 text-blue-800">Resultados</h2>
							<div className="flex space-x-3">
								<label htmlFor={formFields[2].name} className="mb-3 md:mb-2 block w-full">
									<span className="block font-semibold mb-1 text-sm">Qualidade da campanha: </span>
									<select
										value={formFields[2].value}
										name={formFields[2].name}
										id={formFields[2].name}
										onChange={(e) => handleChange(e)}
										className={`w-full py-2 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none bg-white ${
											formFields[2].error.length === 0
												? ' border border-gray-400'
												: 'border border-red-500'
										}
						
							`}
									>
										<option value="0" className="text-gray-600 bg-white">
											Não avaliado
										</option>
										<option value="1" className="text-gray-600 bg-white">
											1
										</option>
										<option value="2" className="text-green-600 bg-white">
											2
										</option>
										<option value="3" className="text-yellow-600 bg-white">
											3
										</option>
										<option value="4" className="text-blue-600 bg-white">
											4
										</option>
										<option value="5" className="text-red-600 bg-white">
											5
										</option>
									</select>
									<span className="text-red-500 text-xs block">{formFields[2].error}</span>
								</label>
							</div>
							<div className="w-full flex space-x-3">
								<label htmlFor={formFields[8].name} className="mb-3 md:mb-2 block w-1/4">
									<span className="block font-semibold mb-1 text-sm">CTR: (%)</span>
									<input
										type={formFields[8].type}
										name={formFields[8].name}
										maxLength={formFields[8].maxLength}
										placeholder="0.00"
										value={formFields[8].value}
										id={formFields[8].name}
										onChange={(e) => handleChange(e, 'PERCENTAGE')}
										onBlur={(e) => handleBlur(e, 'PERCENTAGE')}
										className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
											formFields[8].error.length === 0
												? 'border border-gray-400'
												: 'border border-red-500'
										}`}
									/>
									<span className="text-red-500 text-xs block">{formFields[8].error}</span>
								</label>

								<label htmlFor={formFields[14].name} className="mb-3 md:mb-2 block w-1/4">
									<span className="block font-semibold mb-1 text-sm">Alcance:</span>
									<input
										type={formFields[14].type}
										name={formFields[14].name}
										maxLength={formFields[14].maxLength}
										placeholder="0"
										value={formFields[14].value}
										id={formFields[14].name}
										onChange={(e) => handleChange(e)}
										onBlur={(e) => handleBlur(e)}
										className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
											formFields[14].error.length === 0
												? 'border border-gray-400'
												: 'border border-red-500'
										}`}
									/>
									<span className="text-red-500 text-xs block">{formFields[14].error}</span>
								</label>

								<label htmlFor={formFields[15].name} className="mb-3 md:mb-2 block w-1/4">
									<span className="block font-semibold mb-1 text-sm">Clicks:</span>
									<input
										type={formFields[15].type}
										name={formFields[15].name}
										maxLength={formFields[15].maxLength}
										placeholder="0"
										value={formFields[15].value}
										id={formFields[15].name}
										onChange={(e) => handleChange(e)}
										onBlur={(e) => handleBlur(e)}
										className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
											formFields[15].error.length === 0
												? 'border border-gray-400'
												: 'border border-red-500'
										}`}
									/>
									<span className="text-red-500 text-xs block">{formFields[15].error}</span>
								</label>

								<label htmlFor={formFields[13].name} className="mb-3 md:mb-2 block w-1/4">
									<span className="block font-semibold mb-1 text-sm">Conversões:</span>
									<input
										type={formFields[13].type}
										name={formFields[13].name}
										maxLength={formFields[13].maxLength}
										placeholder="0"
										value={formFields[13].value}
										id={formFields[13].name}
										onChange={(e) => handleChange(e)}
										className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
											formFields[13].error.length === 0
												? 'border border-gray-400'
												: 'border border-red-500'
										}`}
									/>
									<span className="text-red-500 text-xs block">{formFields[13].error}</span>
								</label>
							</div>
						</div>
					</div>
					<div className="w-1/2">
						<div className="mb-5">
							<h2 className="text-lg font-bold mb-3 text-blue-800">Datas</h2>
							<div className="flex space-x-5">
								<div className="w-1/2 flex ">
									<label htmlFor={formFields[3].name} className="mb-3 md:mb-2 block">
										<span className="block font-semibold mb-1 text-sm">Início - dia:</span>
										<input
											type={formFields[3].type}
											name={formFields[3].name}
											maxLength={formFields[3].maxLength}
											placeholder="dd/mm/aa"
											value={formFields[3].value}
											id={formFields[3].name}
											onChange={(e) => handleChange(e, 'DATE')}
											className={`w-full py-1 px-3 rounded-l-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
												formFields[3].error.length === 0
													? 'border border-gray-400'
													: 'border border-red-500'
											}`}
										/>
										<span className="text-red-500 text-xs block">{formFields[3].error}</span>
									</label>
									<label htmlFor={formFields[4].name} className="mb-3 md:mb-2 block">
										<span className="block font-semibold mb-1 text-sm">Início - hora:</span>
										<input
											type={formFields[4].type}
											name={formFields[4].name}
											maxLength={formFields[4].maxLength}
											placeholder="hh/mm"
											value={formFields[4].value}
											id={formFields[4].name}
											onChange={(e) => handleChange(e, 'HOUR')}
											className={`w-full py-1 px-3 rounded-r-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
												formFields[4].error.length === 0
													? 'border border-gray-400'
													: 'border border-red-500'
											}`}
										/>
										<span className="text-red-500 text-xs block">{formFields[4].error}</span>
									</label>
								</div>
								<div className="w-1/2 flex">
									<label htmlFor={formFields[5].name} className="mb-3 md:mb-2 block">
										<span className="block font-semibold mb-1 text-sm">Final - dia:</span>
										<input
											type={formFields[5].type}
											name={formFields[5].name}
											maxLength={formFields[5].maxLength}
											placeholder="dd/mm/aa"
											value={formFields[5].value}
											id={formFields[5].name}
											onChange={(e) => handleChange(e, 'DATE')}
											className={`w-full py-1 px-3 rounded-l-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
												formFields[5].error.length === 0
													? 'border border-gray-400'
													: 'border border-red-500'
											}`}
										/>
										<span className="text-red-500 text-xs block">{formFields[5].error}</span>
									</label>
									<label htmlFor={formFields[6].name} className="mb-3 md:mb-2 block">
										<span className="block font-semibold mb-1 text-sm">Final - hora:</span>
										<input
											type={formFields[6].type}
											name={formFields[6].name}
											maxLength={formFields[6].maxLength}
											placeholder="hh:mm"
											value={formFields[6].value}
											id={formFields[6].name}
											onChange={(e) => handleChange(e, 'HOUR')}
											className={`w-full py-1 px-3 rounded-r-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
												formFields[6].error.length === 0
													? 'border border-gray-400'
													: 'border border-red-500'
											}`}
										/>
										<span className="text-red-500 text-xs block">{formFields[6].error}</span>
									</label>
								</div>
							</div>
						</div>

						<div className="my-5">
							<h2 className="text-lg font-bold mb-3 text-blue-800">Metas</h2>
							<div className="flex space-x-3">
								<div className="w-1/2">
									<label htmlFor={formFields[12].name} className="mb-3 md:mb-2 block w-full ">
										<span className="block font-semibold mb-1 text-sm">Investimento: (R$)</span>
										<input
											type={formFields[12].type}
											name={formFields[12].name}
											maxLength={formFields[12].maxLength}
											placeholder="0,00"
											value={formFields[12].value}
											id={formFields[12].name}
											onChange={(e) => handleChange(e, 'MONEY')}
											onBlur={(e) => handleBlur(e, 'MONEY')}
											className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
												formFields[12].error.length === 0
													? 'border border-gray-400'
													: 'border border-red-500'
											}`}
										/>
										<span className="text-red-500 text-xs block">{formFields[12].error}</span>
									</label>
								</div>

								<div className="w-1/2 flex space-x-3">
									<label htmlFor={formFields[10].name} className="mb-3 md:mb-2 block w-1/2">
										<span className="block font-semibold mb-1 text-sm">CTR meta: (%)</span>
										<input
											type={formFields[10].type}
											name={formFields[10].name}
											maxLength={formFields[10].maxLength}
											placeholder="0.00"
											value={formFields[10].value}
											id={formFields[10].name}
											onChange={(e) => handleChange(e, 'PERCENTAGE')}
											onBlur={(e) => handleBlur(e, 'PERCENTAGE')}
											className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
												formFields[10].error.length === 0
													? 'border border-gray-400'
													: 'border border-red-500'
											}`}
										/>
										<span className="text-red-500 text-xs block">{formFields[10].error}</span>
									</label>

									<label htmlFor={formFields[11].name} className="mb-3 md:mb-2 block w-1/2">
										<span className="block font-semibold mb-1 text-sm">CTL meta: (R$)</span>
										<input
											type={formFields[11].type}
											name={formFields[11].name}
											maxLength={formFields[11].maxLength}
											placeholder="0,00"
											value={formFields[11].value}
											id={formFields[11].name}
											onChange={(e) => handleChange(e, 'MONEY')}
											onBlur={(e) => handleBlur(e, 'MONEY')}
											className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
												formFields[11].error.length === 0
													? 'border border-gray-400'
													: 'border border-red-500'
											}`}
										/>
										<span className="text-red-500 text-xs block">{formFields[11].error}</span>
									</label>
								</div>
							</div>
						</div>

						<label htmlFor={formFields[7].name} className="mb-3 md:mb-2 block">
							<span className="block font-semibold mb-1 text-sm">Anotação:</span>
							<Textarea
								rows={3}
								name={formFields[7].name}
								id={formFields[7].name}
								maxLength={formFields[7].maxLength}
								value={formFields[7].value}
								onChange={(e) => handleChange(e)}
								className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
									formFields[7].error.length === 0
										? 'border border-gray-400'
										: 'border border-red-500'
								}`}
							/>
							<span>{formFields[7].error}</span>
						</label>
					</div>
				</div>
			</div>
			<div className="px-5 h-[70px] flex-shrink-0 border-t border-gray-200 flex items-center justify-between">
				<div className="text-sm">
					<ul className="text-xs flex justify-between bg-gray-100 my-2 px-2 py-2 rounded-md">
						<li>Última atualização</li>
						<li>{scheduleData.schUpdate}</li>
					</ul>
				</div>

				<div className="flex space-x-3 justify-end  ">
					{!sending ? (
						<>
							<button
								type="button"
								onClick={() => closeModal('MODAL_EDIT_POST_SCHEDULE')}
								className="block px-3 py-2 w-auto rounded-md bg-white hover:bg-gray-50 text-gray-600 hover:text-blue-500 flex-shrink-0"
							>
								Cancelar
							</button>
							<button
								type="submit"
								className="px-3 py-2 w-auto rounded-md bg-blue-500 hover:bg-blue-600 text-white shadow flex-shrink-0"
							>
								Confirmar edição
							</button>
						</>
					) : (
						<div className="py-5 text-center">
							<p>Salvando...</p>
							<div className=" flex justify-center items-center h-14">
								<i className="animate-spin absolute bi-arrow-clockwise text-4xl pb-1" />
							</div>
						</div>
					)}
				</div>
			</div>
		</form>
	);
};

export default ScheduleEdit;
