/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line */
import { Link, useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { getHasLocalStorageAuth } from '../helpers/handleStorage';
import Placeholder from '../components/Placeholder';
import HeaderTop from '../components/HeaderTop/HeaderTop';
import ModalCustom from '../components/ModalCustom';

import { getLeadData } from '../Api/clientLeadsHandle';
import PaginationJson from '../components/PaginationJson';

import { END_POINT_BASE } from '../Api/Api';

import { getClientData, getClientCSV } from '../Api/clientsClientHandle';
import Footer from '../components/Footer/Footer';

import 'bootstrap-icons/font/bootstrap-icons.css';
// import LeadEdit from '../components/LeadEdit/LeadEdit';
import LeadEdit from '../components/LeadEdit';
import { getPostData, getPostDataUpdates } from '../Api/clientPostsHandle';
import RateStar from '../components/RateStar/RateStar';

const PageClientPost = () => {
	const history = useHistory();
	const [dataPost, setDataPost] = useState(null);
	const [postDataUpdates, setPostDataUpdates] = useState([]);

	const { pid } = useParams();
	const [isUpdated, setIsUpdated] = useState(false);
	// const [formLoading, setFormLoading] = useState(false);
	// const [formEditFieldsPost, setFormEditFieldsPost] = useState([
	// 	{
	// 		name: 'post_id',
	// 		value: pid,
	// 	},
	// ]);
	const [dataClient, setDataClient] = useState(null);

	const loadingDataPost = async () => {
		await getPostData(pid).then((responseData) => {
			console.log('Fuck', responseData);
			if (responseData.data.status === 1) {
				setDataPost(responseData.data.post);
			} else {
				history.push('/hub');
			}
		});
	};

	const handlePostIsUpdated = async () => {
		await getPostDataUpdates(pid).then((responseData) => {
			console.log('xx', responseData);
			if (responseData.data.status === 1) {
				setPostDataUpdates(responseData.data.post);
			}
		});
	};

	const loadingDataClient = async () => {
		await getClientData().then((responseData) => {
			if (responseData.data.status === 1) {
				console.log('SHOUDL WORK', responseData.data.client);
				setDataClient(responseData.data.client);
			} else {
			}
		});
	};

	useEffect(() => {
		loadingDataClient();
		loadingDataPost();
		handlePostIsUpdated();

		console.log('Vamo que vamo', history);
	}, [pid]);

	if (dataPost === null) {
		return <Placeholder />;
	}

	return (
		<>
			<Helmet>
				<title>Planner: Post {pid}</title>
				<meta name="description" content="BP Planner" />
			</Helmet>

			<div className="min-h-screen flex  flex-col">
				<HeaderTop dataClient={dataClient} btBack isUpdated={isUpdated} title={`Post #${pid}`} />
				{/* <div className="bg-slate-50 py-2 px-5 w-full flex justify-between ">
					<ul className="flex space-x-3 items-center">
						<li className="text-sm">
							<button
								onClick={() => {
									if (!isUpdated) {
										if (history.action !== 'POP') history.goBack();
										history.push('/');
									} else {
										if (window.confirm('Ops! Deseja sair sem salvar?')) {
											history.push('/');
										}
									}
								}}
								className="text-blue-400 hover:underline flex items-center"
							>
								<i className="bi bi-arrow-left-short text-xl" /> <span>Dashboard</span>
							</button>
						</li>
						<li className="text-sm">
							<p className="inline-block text-gray-400">Post #{pid}</p>
						</li>
					</ul>
					<div></div>
				</div> */}

				<LeadEdit
					dataPost={dataPost}
					dataClient={dataClient}
					isUpdated={isUpdated}
					setIsUpdated={setIsUpdated}
					updates={postDataUpdates}
					handlePostIsUpdated={handlePostIsUpdated}
				/>
			</div>

			<Footer />

			{/* <pre className="text-xs">{JSON.stringify(dataClient, null, 1)}</pre> */}
			{/* <pre className="text-xs">{JSON.stringify(dataPayments, null, 1)}</pre> */}
		</>
	);
};

export default PageClientPost;
