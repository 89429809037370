/* eslint-disable */
import { useState, useEffect } from 'react';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { saveAs } from 'file-saver';
import Textarea from 'react-expanding-textarea';

import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import ModalCustom from '../ModalCustom';

import { editDataLead } from '../../Api/clientLeadsHandle';
import { addPost, editDataPost, editPostThumb, removePost } from '../../Api/clientPostsHandle';
import { validateForm, IForm } from '../FormValidation';
import InputFilesLive from '../InputFilesLive/InputFilesLive';
import { getFiles, removeFile, uploadFile } from '../../Api/fileHandle';

import { END_POINT_BASE } from '../../Api/Api';
import ScheduleAdd from '../ScheduleAdd/ScheduleAdd';
import ScheduleEdit from '../ScheduleEdit/ScheduleEdit';
import { getSchedules, removeSchedule } from '../../Api/clientScheduleHandle';
import RateStar from '../RateStar/RateStar';
import { _formatCurrency, copyToClipBoard, moneyToString } from '../../helpers/utils';

/*
título do post
descrição
ref
anotação

*/

const LeadEdit = ({
	dataPost,
	dataClient,
	isUpdated,
	setIsUpdated,
	updates,
	handlePostIsUpdated,
}) => {
	const history = useHistory();
	const [sending, setSending] = useState(false);
	const [modalState, setModalState] = useState({
		MODAL_EDIT_POST_FILES: { status: false },
		MODAL_ADD_POST_SCHEDULE: { status: false },
		MODAL_EDIT_POST_SCHEDULE: { status: false },
	});
	const [formLoading, setFormLoading] = useState(false);

	// schedules
	// const [isUpdated, setIsUpdated] = useState(false);
	const [formEditFieldsSchedules, setFormEditFieldsSchedules] = useState(null);
	const [formEditFieldsSchedulesLocal, setFormEditFieldsSchedulesLocal] = useState({ id: 0 });

	// file
	const [formEditFieldsFilesLocal, setFormEditFieldsFilesLocal] = useState([
		{ name: 'files', value: [] },
	]);
	const [formEditFieldsFiles, setFormEditFieldsFiles] = useState({
		attachment: { name: 'files', value: '', error: '', files: [], total: 0 },
	});

	// file thumb
	const [formEditFieldsFileThumb, setFormEditFieldsFileThumb] = useState([
		{
			name: 'image_thumb',
			value: dataPost.thumb,
			error: '',
			type: 'radio',
			maxLength: 100,
		},
	]);

	const [loadBarProgress, setLoadBarProgress] = useState({
		current: 0,
		total: 0,
	});

	const [formFields, setFormFields] = useState([
		{
			// 0 postid
			name: 'post_id',
			value: dataPost.id,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 1000,
			minLength: 1,
		},
		{
			// 1 status
			name: 'status',
			value: dataPost.status,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 1000,
			minLength: 0,
		},
		{
			// 2 ref
			name: 'ref',
			value: '123',
			error: '',
			type: 'text',
			maxLength: 5000,
			minLength: 1,
		},
		{
			// 3 end
			name: 'title',
			value: dataPost.title,
			error: '',
			type: 'text',
			isRequired: true,
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 4 rate
			name: 'copy',
			value: dataPost.copy,
			error: '',
			type: 'text',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 5 notes
			name: 'notes',
			value: dataPost.notes,
			error: '',
			type: 'text',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 6 channels
			name: 'channels',
			value: 'tue',
			error: '',
			type: 'text',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 7 type
			name: 'type',
			value: dataPost.type,
			error: '',
			type: 'text',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 8 files
			name: 'files',
			value: dataPost.files,
			error: '',
			type: 'text',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 9 channel - facebook
			name: 'channel_facebook',
			value: dataPost.channelFacebook === '1' ? true : false,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 10 channel - instagram
			name: 'channel_instagram',
			value: dataPost.channelInstagram === '1' ? true : false,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 11 channel - newsletter
			name: 'channel_newsletter',
			value: dataPost.channelNewsletter === '1' ? true : false,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 12 channel - googleads
			name: 'channel_googleads',
			value: dataPost.channelGoogleads === '1' ? true : false,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 13 channel - whatsapp
			name: 'channel_whatsapp',
			value: dataPost.channelWhatsapp === '1' ? true : false,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 14 channel - twitter
			name: 'channel_twitter',
			value: dataPost.channelTwitter === '1' ? true : false,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 15 channel - linkedin
			name: 'channel_linkedin',
			value: dataPost.channelLinkedin === '1' ? true : false,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 16 channel - base
			name: 'channel_base',
			value: dataPost.channelBase === '1' ? true : false,
			error: '',
			type: 'checkbox',
			maxLength: 5000,
			minLength: 0,
		},
		{
			// 17 spaces
			name: 'space',
			value: dataPost.spaceId,
			error: '',
			type: 'text',
			maxLength: 100,
			minLength: 0,
		},
	]);

	const [myFormUpdate, setMyFormUpdate] = useState(formFields);

	// ------------------------ MODAL
	const openModal = (modalName, modalData) => {
		setModalState({ ...modalState, [modalName]: { status: true } });
	};

	const closeModal = (modalName) => {
		const documentBody = document.querySelector('body');
		if (documentBody !== null) {
			documentBody.className = '';
		}
		setModalState({ ...modalState, [modalName]: { status: false } });
	};
	// ------------------------ // MODAL

	// if has to save
	const checkIfHasChange = () => {
		console.log('check');
		const isIgual = _.isEqual(formFields, myFormUpdate);
		setIsUpdated(!isIgual);
	};

	// handle thumb
	const handleChangeThumb = (e, form, setForm) => {
		const isCheckBox = e.target.type === 'checkbox';
		let currentValue = e.target.value;

		// console.log("Fuk me")

		setForm(
			form.map((field) => {
				if (field.name === e.target.name) {
					return {
						...field,
						value: isCheckBox ? e.target.checked : currentValue,
						error: '',
					};
				}
				return { ...field };
			}),
		);

		const toastApi = toast.loading('Atualizando thumb...', {
			position: 'bottom-left',
		});

		editPostThumb(dataPost.id, currentValue).then((responseData) => {
			if (responseData.data.status === 1) {
				toast.success('thumb atualizada!', {
					duration: 5000,
					id: toastApi,
				});
			} else {
				toast.error('Erro!', {
					duration: 5000,
					id: toastApi,
				});
			}
		});
	};
	// file
	const handleSubmitEditFiles = async (e) => {
		e.preventDefault();

		const fileListAsArray = Array.from(formEditFieldsFiles.attachment.files);

		const totalFilesToUpload = fileListAsArray.length;
		setLoadBarProgress({ ...loadBarProgress, total: totalFilesToUpload });

		// setLoadBarProgress(totalFilesToUpload);

		if (totalFilesToUpload > 0) {
			setFormLoading(true);
			let currentUpload = 1;
			const toastApi = toast.loading('uploading...', {
				position: 'bottom-left',
			});

			await fileListAsArray.forEach((element, index) => {
				uploadFileSingle(element, toastApi, dataPost.id).then((response) => {
					if (currentUpload >= totalFilesToUpload) {
						loadDataFiles();
						setFormLoading(false);
						setLoadBarProgress({ current: 0, total: 0 });
						closeModal('MODAL_EDIT_POST_FILES');
						handlePostIsUpdated();
						toast.success('Anúncio atualizado!', {
							duration: 5000,
							id: toastApi,
						});
					} else {
						currentUpload = currentUpload + 1;
						setLoadBarProgress({ total: totalFilesToUpload, current: currentUpload });
					}
				});
			});
		} else {
			console.log('Erro, confira se está carregando os arquivos corretos');
		}
	};

	const uploadFileSingle = async (element, toastApi, pid) => {
		await uploadFile(pid, 1, element).then((responseData) => {
			if (responseData.data.status === 1) {
				handlePostIsUpdated();
			} else {
				toast.error('Erro!', {
					duration: 5000,
					id: toastApi,
				});
			}
		});
	};

	const loadDataFiles = async () => {
		await getFiles(dataPost.id).then((responseData) => {
			console.log('done', responseData);
			setFormEditFieldsFilesLocal([
				{ ...formEditFieldsFilesLocal[0], value: responseData.data.files },
			]);

			setFormEditFieldsFiles({
				attachment: { value: '', error: '', files: [], total: 0 },
			});
		});
	};

	//
	const loadDataSchedules = async () => {
		await getSchedules(dataPost.id).then((responseData) => {
			console.log('done', responseData);
			setFormEditFieldsSchedules(responseData.data.schedules);
		});
	};

	// handle input change
	const handleChange = (e) => {
		const isCheckBox = e.target.type === 'checkbox';
		let currentValue = e.target.value;

		// OPTIONAL: clean spaces
		if (
			e.target.name === 'email' ||
			e.target.name === 'password' ||
			e.target.name === 'passwordConfirm' ||
			e.target.name === 'phone'
		) {
			currentValue = currentValue.replace(/\s/g, '');
			currentValue = currentValue.toLowerCase();
		}
		setFormFields(
			formFields.map((field) => {
				if (field.name === e.target.name) {
					return {
						...field,
						value: isCheckBox ? e.target.checked : currentValue,
						error: '',
					};
				}
				return { ...field };
			}),
		);
	};

	// validation form
	const validationForm = () => {
		let hasNoErrors = true;
		const inputRequired = validateForm(formFields, setFormFields);
		if (hasNoErrors) {
			hasNoErrors = inputRequired.hasPassed;
		}
		return hasNoErrors;
	};

	// conclusion
	const handleSubmitAll = async (e) => {
		if (!sending) {
			e.preventDefault();
			const isValid = validationForm();

			if (isValid) {
				const toastId = toast.loading('Salvando...');
				// console.log('bando de bunda mole', formFields);
				setSending(true);
				editDataPost(
					dataPost.id,
					formFields[1].value,
					formFields[2].value,
					formFields[3].value,
					formFields[4].value,
					formFields[5].value,
					formFields[6].value,
					formFields[7].value,
					formFields[8].value,
					formFields[9].value,
					formFields[10].value,
					formFields[11].value,
					formFields[12].value,
					formFields[13].value,
					formFields[14].value,
					formFields[15].value,
					formFields[17].value,
				)
					.then((response) => {
						console.log('arararar', response);
						setSending(false);
						setMyFormUpdate(() => formFields);
						setIsUpdated(false);
						toast.success('Post editado', {
							position: 'bottom-left',
							id: toastId,
						});
						loadDataFiles();
						handlePostIsUpdated();
					})
					.catch((error) => {
						setSending(false);
						console.log('Error', error);
					});
			}
		}
	};

	const handleSubmitScheduleRemove = (scheduleId) => {
		Swal.fire({
			title: 'Deletar agenda',
			text: 'Deseja deletar esta agenda?',
			allowEnterKey: false,
			confirmButtonText: `Deletar agenda`,
			showCancelButton: true,
			cancelButtonText: `Cancelar`,
			allowEscapeKey: true,
			showCloseButton: true,
			didOpen: () => {
				const closeButtonNewIcon = '<i class="hover:underline">Fechar</i>';
				const swalButtonDefault = document.querySelector('.swal-theme-pes__close_icon');
				if (swalButtonDefault) {
					swalButtonDefault.innerHTML = closeButtonNewIcon;
				}
			},
			showClass: {
				popup: 'swal-theme-pes__animation swal-theme-pes__animation--open ',
			},
			hideClass: {
				popup: 'swal-theme-pes__animation swal-theme-pes__animation--close',
			},
			customClass: {
				container: 'swal-theme-pes__container',
				closeButton: 'swal-theme-pes__close_icon',
				htmlContainer: 'swal-theme-pes__html_container',
				actions: 'swal-theme-pes__actions',
				popup: 'swal-theme-pes__popup w-max-sm',
				title: 'swal-theme-pes__title',
				confirmButton:
					'swal-theme-pes__button swal-theme-pes__button--confirm border block px-3 py-2 w-full rounded-md bg-[#4131FA] hover:bg-[#3125BC] text-white shadow mb-3',
				cancelButton:
					'swal-theme-pes__button swal-theme-pes__button--cancel border block px-3 py-2 w-full rounded-md bg-white hover:bg-gray-50 text-gray-600',
				denyButton: 'swal-theme-pes__button swal-theme-pes__button--deny',
			},
		}).then((result) => {
			// setIsLoading(true);
			if (result.isConfirmed) {
				const toastApi = toast.loading('Removendo agenda...', {
					position: 'bottom-left',
				});
				removeSchedule(scheduleId).then((responseData) => {
					if (responseData.data.status === 1) {
						// history.goBack();
						// loadDataFiles();
						loadDataSchedules();
						handlePostIsUpdated();
						toast.success('Agenda removida!', {
							duration: 5000,
							id: toastApi,
						});
					} else {
						toast.error('Erro!', {
							duration: 5000,
							id: toastApi,
						});
					}
				});
			}
		});
	};

	const handleSubmitFileRemove = (fileUrl) => {
		Swal.fire({
			title: 'Deletar arquivo',
			text: 'Deseja deletar esta arquivo?',
			allowEnterKey: false,
			confirmButtonText: `Deletar arquivo`,
			showCancelButton: true,
			cancelButtonText: `Cancelar`,
			allowEscapeKey: true,
			showCloseButton: true,
			didOpen: () => {
				const closeButtonNewIcon = '<i class="hover:underline">Fechar</i>';
				const swalButtonDefault = document.querySelector('.swal-theme-pes__close_icon');
				if (swalButtonDefault) {
					swalButtonDefault.innerHTML = closeButtonNewIcon;
				}
			},
			showClass: {
				popup: 'swal-theme-pes__animation swal-theme-pes__animation--open ',
			},
			hideClass: {
				popup: 'swal-theme-pes__animation swal-theme-pes__animation--close',
			},
			customClass: {
				container: 'swal-theme-pes__container',
				closeButton: 'swal-theme-pes__close_icon',
				htmlContainer: 'swal-theme-pes__html_container',
				actions: 'swal-theme-pes__actions',
				popup: 'swal-theme-pes__popup w-max-sm',
				title: 'swal-theme-pes__title',
				confirmButton:
					'swal-theme-pes__button swal-theme-pes__button--confirm border block px-3 py-2 w-full rounded-md bg-[#4131FA] hover:bg-[#3125BC] text-white shadow mb-3',
				cancelButton:
					'swal-theme-pes__button swal-theme-pes__button--cancel border block px-3 py-2 w-full rounded-md bg-white hover:bg-gray-50 text-gray-600',
				denyButton: 'swal-theme-pes__button swal-theme-pes__button--deny',
			},
		}).then((result) => {
			// setIsLoading(true);
			if (result.isConfirmed) {
				const toastApi = toast.loading('Removendo arquivo...', {
					position: 'bottom-left',
				});
				removeFile(dataPost.id, fileUrl).then((responseData) => {
					if (responseData.data.status === 1) {
						// history.push(`/hub`);
						loadDataFiles();
						handlePostIsUpdated();
						toast.success('Arquivo removido!', {
							duration: 5000,
							id: toastApi,
						});
					} else {
						toast.error('Erro!', {
							duration: 5000,
							id: toastApi,
						});
					}
				});
			}
		});
	};

	const handleSubmitPostRemove = () => {
		Swal.fire({
			title: 'Deletar Post',
			text: 'Deseja deletar esta post?',
			allowEnterKey: false,
			confirmButtonText: `Deletar post`,
			showCancelButton: true,
			cancelButtonText: `Cancelar`,
			allowEscapeKey: true,
			showCloseButton: true,
			didOpen: () => {
				const closeButtonNewIcon = '<i class="hover:underline">Fechar</i>';
				const swalButtonDefault = document.querySelector('.swal-theme-pes__close_icon');
				if (swalButtonDefault) {
					swalButtonDefault.innerHTML = closeButtonNewIcon;
				}
			},
			showClass: {
				popup: 'swal-theme-pes__animation swal-theme-pes__animation--open ',
			},
			hideClass: {
				popup: 'swal-theme-pes__animation swal-theme-pes__animation--close',
			},
			customClass: {
				container: 'swal-theme-pes__container',
				closeButton: 'swal-theme-pes__close_icon',
				htmlContainer: 'swal-theme-pes__html_container',
				actions: 'swal-theme-pes__actions',
				popup: 'swal-theme-pes__popup w-max-sm',
				title: 'swal-theme-pes__title',
				confirmButton:
					'swal-theme-pes__button swal-theme-pes__button--confirm border block px-3 py-2 w-full rounded-md bg-[#4131FA] hover:bg-[#3125BC] text-white shadow mb-3',
				cancelButton:
					'swal-theme-pes__button swal-theme-pes__button--cancel border block px-3 py-2 w-full rounded-md bg-white hover:bg-gray-50 text-gray-600',
				denyButton: 'swal-theme-pes__button swal-theme-pes__button--deny',
			},
		}).then((result) => {
			// setIsLoading(true);
			if (result.isConfirmed) {
				const toastApi = toast.loading('Removendo post...', {
					position: 'bottom-left',
				});
				removePost(dataPost.id).then((responseData) => {
					if (responseData.data.status === 1) {
						// history.goBack();
						history.push(`/hub`);
						// loadDataFiles();
						// loadDataSchedules();
						toast.success('post removido!', {
							duration: 5000,
							id: toastApi,
						});
					} else {
						toast.error('Erro!', {
							duration: 5000,
							id: toastApi,
						});
					}
				});
			}
		});
	};

	//
	useEffect(() => {
		const handler = (e) => {
			e.preventDefault();
			e.returnValue = '';
		};

		// if the form is NOT unchanged, then set the onbeforeunload
		if (isUpdated) {
			window.addEventListener('beforeunload', handler);
			// clean it up, if the dirty state changes
			return () => {
				window.removeEventListener('beforeunload', handler);
			};
		}

		// since this is not dirty, don't do anything
		return () => {};
	}, [isUpdated]);

	useEffect(() => {
		checkIfHasChange();
	}, [formFields]);

	useEffect(() => {
		// loadingDataPost();
		loadDataFiles();
		loadDataSchedules();
	}, [dataPost.id]);

	useEffect(() => {
		console.log('poten', dataPost);
	}, [dataPost]);

	return (
		<>
			<ModalCustom
				status={modalState.MODAL_EDIT_POST_SCHEDULE.status}
				closeModal={closeModal}
				modal="MODAL_EDIT_POST_SCHEDULE"
				className="p-0 justify-between absolute right-0 top-0 m-0 h-full max-w-[1000px]"
			>
				<ScheduleEdit
					closeModal={closeModal}
					scheduleData={formEditFieldsSchedulesLocal}
					loadDataSchedules={loadDataSchedules}
					handlePostIsUpdated={handlePostIsUpdated}
				/>
				{/* <div className="flex flex-col items-stretch h-full">
					<div className="h-[100px] flex-shrink-0 bg-red-400">cabecalho</div>
					<div className="basis-full h-full bg-blue-400 overflow-y-auto">
					d
					</div>
					<div className="h-[100px] flex-shrink-0 bg-green-400">footer</div>
				</div> */}
			</ModalCustom>

			<ModalCustom
				status={modalState.MODAL_ADD_POST_SCHEDULE.status}
				closeModal={closeModal}
				modal="MODAL_ADD_POST_SCHEDULE"
				className="p-0 justify-between absolute right-0 top-0 m-0 h-full max-w-[600px]"
			>
				<ScheduleAdd
					closeModal={closeModal}
					loadDataSchedules={loadDataSchedules}
					postId={dataPost.id}
					handlePostIsUpdated={handlePostIsUpdated}
				/>
			</ModalCustom>

			<ModalCustom
				status={modalState.MODAL_EDIT_POST_FILES.status}
				closeModal={closeModal}
				modal="MODAL_EDIT_POST_FILES"
				className=" p-3 md:p-5 justify-between absolute right-0 top-0 m-0 h-full max-w-[600px]"
			>
				<h1 className="font-bold text-md mb-5 ">Cadastro de arquivo</h1>
				<form onSubmit={handleSubmitEditFiles}>
					<label htmlFor="file-label" className="block mb-3 ">
						<InputFilesLive
							inputValue={formEditFieldsFiles}
							maxFiles={30}
							maxSize={500000}
							customClass="border block w-full rounded-md outline-0 border-blue-400 border-dashed text-center"
							isLoading={formLoading}
							inputValueFile="attachment"
							setInputValue={setFormEditFieldsFiles}
						/>
						<span className="text-sm text-red-500 italic">
							{formEditFieldsFiles.attachment.error}
						</span>
					</label>

					<div className="bg-gray-100 px-5 py-3 mb-3">
						{formLoading ? (
							<div>
								Carregando arquivos: {loadBarProgress.current}/{loadBarProgress.total}
							</div>
						) : (
							<>
								<p>Limite de arquivos: 30</p>
								<p>Limite de tamanho de todos os arquivos: 500mb</p>
							</>
						)}
					</div>

					{!formLoading && (
						<button
							type="submit"
							className="border block px-2 py-2 w-full rounded-md bg-[#4131FA] hover:bg-[#3125BC] text-white shadow mb-3"
						>
							Confirmar
						</button>
					)}
					<button
						type="button"
						onClick={() => closeModal('MODAL_EDIT_POST_FILES')}
						className="border block px-2 py-2 w-full rounded-md bg-white hover:bg-gray-50 text-gray-600 "
					>
						Cancelar
					</button>
				</form>
			</ModalCustom>

			<div className="bg-white max-w-7xl w-full mx-auto">
				{/* <pre>{JSON.stringify(isUpdated.toString(), null, 1)}</pre> */}

				<form
					onSubmit={handleSubmitAll}
					className="py-2 px-5 w-full flex justify-between space-x-10"
				>
					<div className="basis-auto  w-[300px] shrink-0 space-y-2">
						<div className="flex justify-between my-5">
							<h2 className="font-bold text-lg text-rose-500">Agendas</h2>
							<button
								className="text-[#0697f2]"
								type="button"
								onClick={() => openModal('MODAL_ADD_POST_SCHEDULE')}
							>
								Adicionar +
							</button>
						</div>
						<div className="basis-auto  w-full space-y-2">
							{!formEditFieldsSchedules ? (
								<p>loading</p>
							) : formEditFieldsSchedules.length > 0 ? (
								formEditFieldsSchedules.map((schedule) => {
									return (
										<BlockSchedule
											key={schedule.id}
											schedule={schedule}
											handleSubmitScheduleRemove={handleSubmitScheduleRemove}
											openModal={openModal}
											setFormEditFieldsSchedulesLocal={setFormEditFieldsSchedulesLocal}
										/>
									);
								})
							) : (
								<div className="rounded-md bg-gray-50 p-5 space-y-2 text-center text-sm">
									Sem histórico de agenda
								</div>
							)}
							{/* <BlockSchedule schedule={schedule} /> */}
							{/* <BlockSchedule /> */}
							{/* <BlockSchedule /> */}
						</div>
					</div>
					<div className="basis-full w-full">
						<div className="flex justify-between my-5">
							<h2 className="font-bold text-lg text-rose-500">Conteúdo do post</h2>
						</div>

						<div className="pb-5 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-3">
							<div className={`flex flex-col`}>
								{/* <img
									src={`${END_POINT_BASE}/uploads/uploads/3f7e5b86f49a9ae50c1d078a089c74c3_p.jpg.jpg`}
									alt="photo post"
									className="object-cover aspect-square w-full "
								/> */}
								<button
									type="button"
									onClick={() => openModal('MODAL_EDIT_POST_FILES')}
									className="border-2 border-dashed  rounded-lg overflow-hidden border-blue-500 block px-2 py-2 w-full bg-white hover:bg-gray-50 text-gray-600 aspect-square text-blue-500"
								>
									<i className="bi-plus-lg text-2xl text-blue-500" />
								</button>
							</div>

							{formEditFieldsFilesLocal[0].value.length > 0 ? (
								formEditFieldsFilesLocal[0].value.map((file, index) => {
									return (
										<div
											key={file.url_p}
											className={`flex flex-col  rounded-lg overflow-hidden shadow-md`}
										>
											<div className={`relative`}>
												{file.type === '1' ? (
													<img
														src={`${END_POINT_BASE}/uploads/${file.url_p}.jpg`}
														alt="photo post"
														className="object-cover aspect-square w-full"
													/>
												) : (
													<video className="aspect-square" controls preload="metadata">
														<source
															src={`${END_POINT_BASE}/uploads/${file.url}.mp4`}
															type="video/ogg"
														/>
														Your browser does not support the video tag.
													</video>
												)}

												<div className="absolute top-0 right-0 p-2 w-full">
													<div className="flex justify-between w-full basis-full space-x-1">
														<button
															download
															type="button"
															onClick={() =>
																saveAs(
																	`${
																		file.type === '1'
																			? `${END_POINT_BASE}/uploads/${file.url}.jpg`
																			: `${END_POINT_BASE}/uploads/${file.url}.mp4`
																	}`,
																	`${
																		file.type === '1'
																			? `${dataPost.title}_${index}.jpg`
																			: `${dataPost.title}_${index}.mp4`
																	}`,
																)
															}
															className="bg-white hover:text-[#0697f2] flex justify-center items-center text-sm w-1/3 rounded-lg"
														>
															<i className="bi bi-box-arrow-up-right text-sm" />
														</button>

														<button
															type="button"
															// onClick={() => handleSubmitFileRemove(file.url)}
															className="bg-white hover:text-[#0697f2]  w-1/3 rounded-lg overflow-hidden"
														>
															<label
																htmlFor={file.url_p}
																className={`relative border  h-full cursor-pointer flex justify-center items-center`}
															>
																{Number(file.id) === Number(formEditFieldsFileThumb[0].value) ? (
																	<i className="bi bi-star-fill text-sm" />
																) : (
																	<i className="bi bi-star text-sm" />
																)}

																<input
																	type={formEditFieldsFileThumb[0].type}
																	name={formEditFieldsFileThumb[0].name}
																	id={file.url_p}
																	placeholder="thumb..."
																	maxLength={formEditFieldsFileThumb[0].maxLength}
																	value={file.id}
																	checked={
																		Number(file.id) === Number(formEditFieldsFileThumb[0].value) &&
																		true
																	}
																	onChange={(e) =>
																		handleChangeThumb(
																			e,
																			formEditFieldsFileThumb,
																			setFormEditFieldsFileThumb,
																		)
																	}
																	className=" px-3 py-2 w-full  hidden rounded-md"
																/>
															</label>
															{/* <i className="bi bi-trash text-sm" /> */}
														</button>
														<button
															type="button"
															onClick={() => handleSubmitFileRemove(file.url)}
															className="bg-white hover:text-[#0697f2] text-gray-500 flex justify-center items-center text-sm w-1/3 h-auto aspect-square rounded-lg"
														>
															<i className="bi bi-trash text-sm" />
														</button>
													</div>
												</div>
											</div>
										</div>
									);
								})
							) : (
								<p className="py-3 text-sm text-gray-300 "></p>
							)}
						</div>

						<label htmlFor={formFields[3].name} className="mb-3 md:mb-3 block">
							<div className="flex justify-between ">
								<span className="block font-semibold text-md">Título:</span>
								<button
									onClick={() => copyToClipBoard(formFields[3].value)}
									type="button"
									className="py-1 px-0 rounded-0ull bg-white text-gray-500  hover:text-blue-500 text-sm transition"
								>
									<i className="bi bi-clipboard text-sm" /> Copiar
								</button>
							</div>

							<input
								type={formFields[3].type}
								name={formFields[3].name}
								maxLength={formFields[3].maxLength}
								value={formFields[3].value}
								id={formFields[3].name}
								onChange={(e) => handleChange(e)}
								className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
									formFields[3].error.length === 0
										? 'border border-gray-400'
										: 'border border-red-500'
								}`}
							/>
							<div className="text-red-500 text-xs flex justify-end">
								<span>{formFields[3].error}</span>
							</div>
						</label>

						<label htmlFor={formFields[4].name} className="mb-3 md:mb-3 block">
							<div className="flex justify-between">
								<span className="block font-semibold  text-md">Copy:</span>
								<button
									onClick={() => copyToClipBoard(formFields[4].value)}
									type="button"
									className="py-0 px-0 rounded-full  text-gray-500  hover:text-blue-500 text-sm transition"
								>
									<i className="bi bi-clipboard text-sm" /> Copiar
								</button>
							</div>
							<Textarea
								rows={3}
								name={formFields[4].name}
								id={formFields[4].name}
								maxLength={formFields[4].maxLength}
								value={formFields[4].value}
								onChange={(e) => handleChange(e)}
								className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
									formFields[4].error.length === 0
										? 'border border-gray-400'
										: 'border border-red-500'
								}`}
							/>
							<div className="text-red-500 text-xs flex justify-end">
								<span>{formFields[4].error}</span>
							</div>
						</label>

						<label htmlFor={formFields[5].name} className="mb-3 md:mb-3 block">
							<span className="block font-semibold mb-1 text-sm">Anotações:</span>
							<Textarea
								rows={3}
								name={formFields[5].name}
								id={formFields[5].name}
								maxLength={formFields[5].maxLength}
								value={formFields[5].value}
								onChange={(e) => handleChange(e)}
								className={`w-full py-1 px-3 rounded-md border-[#dedede] focus:ring-1 ring-tablue-500 outline-none ${
									formFields[5].error.length === 0
										? 'border border-gray-400'
										: 'border border-red-500'
								}`}
							/>
							<div className="text-red-500 text-xs flex justify-end">
								<span>{formFields[5].error}</span>
							</div>
						</label>

						<div className="flex justify-end my-5">
							<button
								className="text-gray-400 hover:text-red-500"
								type="button"
								onClick={() => {
									handleSubmitPostRemove();
								}}
							>
								Deletar este post
							</button>
						</div>
					</div>
					<div className="basis-auto  w-[300px] shrink-0">
						<div className="flex justify-between my-5">
							<h2 className="font-bold text-lg text-rose-500">&nbsp;</h2>
						</div>
						<div className="rounded-md bg-gray-50 p-5 space-y-2">
							<label htmlFor={formFields[1].name} className="mb-3 md:mb-3 block">
								<span className="block font-semibold mb-1 text-sm">Status do post: </span>
								<select
									value={formFields[1].value}
									name={formFields[1].name}
									id={formFields[1].name}
									onChange={(e) => handleChange(e)}
									className={`w-full py-1 px-2 rounded-md select-none focus:ring-2 ring-tablue-500 outline-none bg-white ${
										formFields[1].error.length === 0
											? 'border border-gray-400'
											: 'border border-red-500'
									}`}
								>
									<option value="1" className="bg-white">
										Referência
									</option>
									<option value="2" className="bg-white">
										Rascunho
									</option>
									<option value="3" className="bg-white">
										Em análise
									</option>
									<option value="4" className="bg-white">
										Aprovado
									</option>
								</select>
								<span className="text-red-500 text-xs block">{formFields[1].error}</span>
							</label>
							<label htmlFor={formFields[17].name} className="mb-3 md:mb-3 block w-full">
								<span className="block font-semibold mb-1 text-sm">Espaço: </span>
								<select
									value={formFields[17].value}
									name={formFields[17].name}
									id={formFields[17].name}
									onChange={(e) => handleChange(e)}
									className={`w-full py-1 px-2 rounded-md select-none focus:ring-2 ring-tablue-500 outline-none bg-white ${
										formFields[17].error.length === 0
											? ' border border-gray-400'
											: 'border border-red-500'
									}
							`}
								>
									{dataClient?.spaces.map((space, index) => {
										return (
											<option
												key={`${space.id}-${index}`}
												value={space.id}
												className="text-gray-600 bg-white"
											>
												{space.name}
											</option>
										);
									})}
								</select>
							</label>
							{!isUpdated ? (
								<div className="flex justify-end">
									<button
										type="button"
										onClick={() => {
											toast.error('Não há nada para atualizar!', {
												position: 'bottom-left',
												duration: 5000,
												icon: '🙄',
											});
										}}
										className="bg-gray-500 hover:bg-gray-600 py-2 px-3 text-white rounded-md "
									>
										Atualizar
									</button>
								</div>
							) : (
								<div className="flex justify-end">
									{!sending ? (
										<button
											type="submit"
											className="bg-[#4131FA] hover:bg-[#3125BC] py-2 px-3 text-white rounded-md "
										>
											Atualizar
										</button>
									) : (
										<div
											type="button"
											className="bg-[#4131FA] hover:bg-[#3125BC] py-2 px-3 text-white rounded-md pr-5 "
										>
											Salvando <i className="animate-spin absolute bi-arrow-clockwise text-md " />
										</div>
									)}
								</div>
							)}
						</div>

						<div className="flex justify-between my-5">
							<h2 className="font-bold text-lg text-rose-500">Canais</h2>
						</div>
						<div className="rounded-md bg-gray-50 p-5 space-y-2">
							<label
								htmlFor={formFields[9].name}
								className="flex items-center  hover:text-blue-500 cursor-pointer  rounded-sm w-1/2 sm:w-1/4 md:w-auto"
							>
								<input
									type={formFields[9].type}
									name={formFields[9].name}
									maxLength={formFields[9].maxLength}
									checked={Boolean(Number(formFields[9].value))}
									value={formFields[9].value}
									id={formFields[9].name}
									onChange={(e) => handleChange(e)}
									className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
										formFields[9].error.length === 0
											? 'border border-gray-400'
											: 'border border-red-500'
									}`}
								/>

								<span className="text-sm ml-1">Facebook</span>
							</label>
							<label
								htmlFor={formFields[10].name}
								className="flex items-center  hover:text-blue-500 cursor-pointer  rounded-sm w-1/2 sm:w-1/4 md:w-auto"
							>
								<input
									type={formFields[10].type}
									name={formFields[10].name}
									maxLength={formFields[10].maxLength}
									checked={Boolean(Number(formFields[10].value))}
									value={formFields[10].value}
									id={formFields[10].name}
									onChange={(e) => handleChange(e)}
									className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
										formFields[10].error.length === 0
											? 'border border-gray-400'
											: 'border border-red-500'
									}`}
								/>

								<span className="text-sm ml-1">Instagram</span>
							</label>
							<label
								htmlFor={formFields[11].name}
								className="flex items-center  hover:text-blue-500 cursor-pointer  rounded-sm w-1/2 sm:w-1/4 md:w-auto"
							>
								<input
									type={formFields[11].type}
									name={formFields[11].name}
									maxLength={formFields[11].maxLength}
									checked={Boolean(Number(formFields[11].value))}
									value={formFields[11].value}
									id={formFields[11].name}
									onChange={(e) => handleChange(e)}
									className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
										formFields[11].error.length === 0
											? 'border border-gray-400'
											: 'border border-red-500'
									}`}
								/>

								<span className="text-sm ml-1">Newsletter</span>
							</label>
							<label
								htmlFor={formFields[12].name}
								className="flex items-center  hover:text-blue-500 cursor-pointer  rounded-sm w-1/2 sm:w-1/4 md:w-auto"
							>
								<input
									type={formFields[12].type}
									name={formFields[12].name}
									maxLength={formFields[12].maxLength}
									checked={Boolean(Number(formFields[12].value))}
									value={formFields[12].value}
									id={formFields[12].name}
									onChange={(e) => handleChange(e)}
									className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
										formFields[12].error.length === 0
											? 'border border-gray-400'
											: 'border border-red-500'
									}`}
								/>

								<span className="text-sm ml-1">Google Ads</span>
							</label>
							<label
								htmlFor={formFields[13].name}
								className="flex items-center  hover:text-blue-500 cursor-pointer  rounded-sm w-1/2 sm:w-1/4 md:w-auto"
							>
								<input
									type={formFields[13].type}
									name={formFields[13].name}
									maxLength={formFields[13].maxLength}
									checked={Boolean(Number(formFields[13].value))}
									value={formFields[13].value}
									id={formFields[13].name}
									onChange={(e) => handleChange(e)}
									className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
										formFields[13].error.length === 0
											? 'border border-gray-400'
											: 'border border-red-500'
									}`}
								/>

								<span className="text-sm ml-1">Whatsapp</span>
							</label>
							<label
								htmlFor={formFields[14].name}
								className="flex items-center  hover:text-blue-500 cursor-pointer  rounded-sm w-1/2 sm:w-1/4 md:w-auto"
							>
								<input
									type={formFields[14].type}
									name={formFields[14].name}
									maxLength={formFields[14].maxLength}
									checked={Boolean(Number(formFields[14].value))}
									value={formFields[14].value}
									id={formFields[14].name}
									onChange={(e) => handleChange(e)}
									className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none ${
										formFields[14].error.length === 0
											? 'border border-gray-400'
											: 'border border-red-500'
									}`}
								/>

								<span className="text-sm ml-1">Twitter</span>
							</label>
							<label
								htmlFor={formFields[15].name}
								className="flex items-center  hover:text-blue-500 cursor-pointer  rounded-sm w-1/2 sm:w-1/4 md:w-auto"
							>
								<input
									type={formFields[15].type}
									name={formFields[15].name}
									maxLength={formFields[15].maxLength}
									checked={Boolean(Number(formFields[15].value))}
									value={formFields[15].value}
									id={formFields[15].name}
									onChange={(e) => handleChange(e)}
									className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none bg-red-300 ${
										formFields[15].error.length === 0
											? 'border border-gray-400'
											: 'border border-red-500'
									}`}
								/>

								<span className="text-sm ml-1">Linkedin</span>
							</label>
							<label className="flex items-center px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto">
								<input
									type="checkbox"
									checked="true"
									readOnly
									disabled
									className={`w-5 py-0 px-2 rounded-md focus:ring-2 ring-tablue-500 outline-none `}
								/>

								<span className="text-sm">Base</span>
							</label>
						</div>
						{/* <div className="flex justify-between my-5">
							<h2 className="font-bold text-lg text-rose-500">Updates</h2>
						</div>
						<div className="rounded-md bg-gray-50 p-5 py-3 space-y-2 max-h-[200px] overflow-y-scroll">
							<ul className="text-xs divide-slate-200 divide-y">
								{updates.map((update) => {
									return (
										<li className="py-2 flex flex-col">
											<span>
												{update.client}: {update.message}
											</span>
											<span>
												{update.date} {update.id}
											</span>
										</li>
									);
								})}
							</ul>
						</div> */}
					</div>
				</form>
			</div>
		</>
	);
};

export default LeadEdit;

const BlockSchedule = ({
	schedule,
	handleSubmitScheduleRemove,
	openModal,
	setFormEditFieldsSchedulesLocal,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div type="button" className="rounded-md bg-gray-50 pb-5 space-y-2  transition w-full">
			<button
				type="button"
				onClick={() => {
					setFormEditFieldsSchedulesLocal(schedule);
					openModal('MODAL_EDIT_POST_SCHEDULE');
				}}
				className="rounded-md bg-gray-50 p-5 space-y-2 hover:bg-[#f3f5f8] transition w-full"
			>
				{schedule.rate > 0 && (
					<div className="flex justify-between">
						<p className="font-bold">Desempenho</p>
						<RateStar rate={schedule.rate} />
					</div>
				)}
				<div className="text-sm">
					<p className="flex justify-between">
						<span className="font-bold">Início</span>
						<span>
							{schedule.startDay} {schedule.startHour}h
						</span>
					</p>
					<p className="flex justify-between">
						<span className="font-bold">Fim</span>
						<span>
							{schedule.endDay} {schedule.endHour}h
						</span>
					</p>
					<p className="flex justify-between">
						<span className="font-bold">Investimento</span>
						<span>R$ {moneyToString(schedule.investment)}</span>
					</p>
					<p className="flex justify-between">
						<span className="font-bold">Dias de campanha</span>
						<span>{schedule.campaignDays}</span>
					</p>
					<p className="flex justify-between">
						<span className="font-bold">Situação</span>
						<span>{schedule.isActive ? 'Anúncio visível' : 'Anúncio expirado'}</span>
					</p>
					<p className="flex justify-between">
						<span className="font-bold">Leads</span>
						<span>{schedule.conversions}</span>
					</p>
				</div>

				<div className={` ${isExpanded ? 'text-left' : 'hidden'} text-sm transition`}>
					<h4 className="font-bold">CTC</h4>
					<ul className="text-sm flex justify-between">
						<li>{schedule.ctl === '0.00' ? '-' : `R$ ${moneyToString(schedule.ctc)}`}</li>
						<li>{schedule.clicks} clicks</li>
					</ul>
				</div>
				<div className={` ${isExpanded ? 'text-left' : 'hidden'} text-sm transition`}>
					<h4 className="font-bold">
						CTL
						{schedule.ctl !== '0.00' &&
							(Number(schedule.ctl) <= Number(schedule.ctlMeta) ? (
								<i className="bi bi-arrow-up-short text-green-600" />
							) : (
								<i className="bi bi-arrow-down-short text-red-500" />
							))}
					</h4>
					<ul className="text-sm flex justify-between">
						<li>{schedule.ctl === '0.00' ? '-' : `R$ ${moneyToString(schedule.ctl)}`}</li>
						<li>Meta: R$ {moneyToString(schedule.ctlMeta)}</li>
					</ul>
				</div>
				<div className={` ${isExpanded ? 'text-left' : 'hidden'} text-sm transition`}>
					<h4 className="font-bold">
						CTR
						{schedule.ctr !== '0.00' &&
							(Number(schedule.ctr) >= Number(schedule.ctrMeta) ? (
								<i className="bi bi-arrow-up-short text-green-600" />
							) : (
								<i className="bi bi-arrow-down-short text-red-500" />
							))}
					</h4>
					<ul className="text-sm flex justify-between">
						<li>{schedule.ctr === '0.00' ? '-' : `${schedule.ctr}%`}</li>
						<li>Meta: {schedule.ctrMeta}%</li>
					</ul>
				</div>
				<div className={` ${isExpanded ? 'text-left' : 'hidden'} text-sm transition`}>
					<ul className="text-xs flex justify-between bg-gray-100 my-2 px-2 py-2 rounded-md">
						<li>Última atualização</li>
						<li>{schedule.schUpdate}</li>
					</ul>
				</div>

				<div className={` ${isExpanded ? 'text-left' : 'hidden'} text-sm transition`}>
					{schedule.note.trim() !== '' && (
						<div className="text-xs bg-gray-100 py-2 px-2 rounded-md">
							<p className="whitespace-pre-line">{schedule.note}</p>
						</div>
					)}
				</div>
			</button>
			<div className="text-sm flex justify-between pt-2 px-5">
				<button
					className="text-gray-300 hover:text-red-600"
					type="button"
					onClick={() => {
						handleSubmitScheduleRemove(schedule.id);
					}}
				>
					Deletar
				</button>
				<button
					className="text-blue-500 space-x-1 text-sm flex items-center"
					type="button"
					onClick={() => setIsExpanded(!isExpanded)}
				>
					{!isExpanded ? (
						<>
							<span>Expandir</span>
							<i className="bi-chevron-down"></i>
						</>
					) : (
						<>
							<span>Encolher</span>
							<i className="bi-chevron-up"></i>
						</>
					)}
				</button>
			</div>
		</div>
	);
};
