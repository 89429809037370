/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line */
import { Link, useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { getHasLocalStorageAuth } from '../../helpers/handleStorage';
import Placeholder from '../Placeholder';
import HeaderTop from '../HeaderTop/HeaderTop';
import ModalCustom from '../ModalCustom';

import { getLeadData } from '../../Api/clientLeadsHandle';
import PaginationJson from '../PaginationJson';

import { END_POINT_BASE } from '../../Api/Api';

import { getClientData, getClientCSV } from '../../Api/clientsClientHandle';
import Footer from '../Footer/Footer';

// import 'bootstrap-icons/font/bootstrap-icons.css';
// import LeadEdit from '../LeadEdit/LeadEdit';
import LeadEdit from '../LeadEdit';
import { getPostData } from '../../Api/clientPostsHandle';
import { getFiles } from '../../Api/fileHandle';
import { copyToClipBoard } from '../../helpers/utils';
import RateStar from '../RateStar/RateStar';
import { IconChannel } from '../../pages/PageClientHub';

const PostView = ({ closeModal, dataPostId, status }) => {
	const history = useHistory();
	const [dataPost, setDataPost] = useState(null);

	// file
	const [formEditFieldsFilesLocal, setFormEditFieldsFilesLocal] = useState(null);

	// const { pid } = useParams();
	const [formLoading, setFormLoading] = useState(false);
	const [formPostIdCurrent, setFormPostIdCurrent] = useState(dataPostId);
	const [dataClient, setDataClient] = useState(null);

	const loadingDataPost = async () => {
		await getPostData(dataPostId).then((responseData) => {
			console.log('Fuck', responseData);
			if (responseData.data.status === 1) {
				setDataPost(responseData.data.post);
				loadDataFiles();
				//
				setFormPostIdCurrent(dataPostId);
				// loadDataFiles();
			} else {
				console.log('Error');
				// history.push('/hub');
			}
		});
	};

	const loadDataFiles = async () => {
		await getFiles(dataPostId).then((responseData) => {
			console.log('done', responseData);
			setFormEditFieldsFilesLocal(responseData.data.files);

			// setFormEditFieldsFiles({
			// 	attachment: { value: '', error: '', files: [], total: 0 },
			// });
		});
	};

	useEffect(() => {
		setFormEditFieldsFilesLocal(null);
		// loadDataFiles();
		// loadingDataClient();
		loadingDataPost();

		console.log('Vamo que vamo', history);
	}, [dataPostId]);

	useEffect(() => {
		console.log('x', typeof dataPostId, typeof formPostIdCurrent);
		if (!status) {
			console.log('nulllllll');
			setDataPost(null);
			setFormEditFieldsFilesLocal(null);
		}
		if (status && formPostIdCurrent === dataPostId) {
			setFormEditFieldsFilesLocal(null);
			// loadDataFiles();
			// loadingDataClient();
			loadingDataPost();
		}
	}, [status]);

	if (dataPost === null) {
		return <Placeholder />;
	}

	return (
		<div className="flex flex-col items-stretch h-full">
			<div className="px-5 h-[50px] flex-shrink-0  border-gray-200 flex items-center justify-between">
				<h2 className="text-lg font-bold text-[#FD2E91] break-all">
					#{dataPost.id} {dataPost.title}
				</h2>
				<button
					onClick={() => closeModal('MODAL_VIEW_POST')}
					className="text-blue-500 hover:underline"
				>
					<i className="bi-x text-4xl" />
				</button>
			</div>

			<div className="basis-full h-full bg-white overflow-y-auto px-3 md:px-5 py-3 md:py-5">
				<div className="flex justify-between items-center">
					<ul className="flex flex-wrap -ml-1">
						{dataPost.channels.map((channel, index) => {
							return <IconChannel key={`channel-${index}`} icon={channel} />;
						})}
					</ul>
				</div>
				{/* <p>Status da publicação: {dataPost.publish}</p> */}
				<div
					className={` py-5  ${
						formEditFieldsFilesLocal?.length > 0 && 'grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-3'
					}`}
				>
					{formEditFieldsFilesLocal?.length > 0 ? (
						formEditFieldsFilesLocal.map((file, index) => {
							return (
								<div
									key={file.url_p}
									className={`flex flex-col  rounded-lg overflow-hidden shadow-md`}
								>
									<div className={`relative`}>
										{file.type === '1' ? (
											<img
												src={`${END_POINT_BASE}/uploads/${file.url_p}.jpg`}
												alt="photo post"
												className="object-cover aspect-square w-full"
											/>
										) : (
											<video className="aspect-square" controls preload="metadata">
												<source
													src={`${END_POINT_BASE}/uploads/${file.url}.mp4`}
													type="video/ogg"
												/>
												Your browser does not support the video tag.
											</video>
										)}

										<div className="absolute top-0 right-0 p-2 w-full">
											<div className="flex justify-between w-full basis-full space-x-1">
												<button
													download
													type="button"
													onClick={() =>
														saveAs(
															`${
																file.type === '1'
																	? `${END_POINT_BASE}/uploads/${file.url}.jpg`
																	: `${END_POINT_BASE}/uploads/${file.url}.mp4`
															}`,
															`${
																file.type === '1'
																	? `${dataPost.title}_${index}.jpg`
																	: `${dataPost.title}_${index}.mp4`
															}`,
														)
													}
													className="bg-white hover:text-[#0697f2] text-gray-500 flex justify-center items-center text-sm w-1/4 h-auto aspect-square rounded-lg"
												>
													<i className="bi bi-box-arrow-up-right text-sm" />
												</button>
											</div>
										</div>
									</div>
								</div>
							);
						})
					) : (
						<p className="py-3 text-sm text-gray-300 ">Sem arquivo cadastrada</p>
					)}
				</div>
				<div className="flex flex-col space-y-5">
					<p className="text-md space-x-1 text-[#909DAD]">
						<i className="bi bi-bookmark-check" />
						<span>{dataPost.spaceName}</span>
					</p>
					<div className="flex flex-col space-y-1">
						<p className="text-md whitespace-pre-line">{dataPost.copy}</p>
					</div>
					<hr className="my-2" />

					<div className="flex flex-col space-y-3">
						<h2 className="text-lg font-bold text-rose-500">Agenda</h2>
						<p>Total de dias: {dataPost.daysCampaign}</p>
						<div className="py-1">
							{dataPost.rate !== 0 && <RateStar rate={dataPost.rate} />}
							<div className="my-2">
								{dataPost.campaignsList.map((schedule, index) => {
									return (
										<p className="text-xs" key={`${schedule.id}-${index}`}>
											<i className="bi bi-star-fill text-xs" /> {schedule.rate}
											/5 | CTR: {schedule.ctr}% | CTL: R$ {schedule.ctl}
										</p>
									);
								})}
							</div>
						</div>
					</div>

					<div className="flex flex-col space-y-3 break-all">
						{dataPost.notes.trim() !== '' && (
							<div className="my-2 bg-[#f8f8f8] py-3 px-5 rounded-md text-sm">
								<h2 className="text-lg font-bold text-rose-500">Anotações</h2>
								<p className="text-md whitespace-pre-line">{dataPost.notes}</p>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="px-5 h-[70px] flex-shrink-0 border-t border-gray-200 flex items-center justify-end">
				<div className="flex space-x-3 justify-end break-all">
					<button
						onClick={() => copyToClipBoard(dataPost.copy)}
						className="text-[#4131FA] bg-[#F6F5FF] rounded-md inline-block py-2 px-3"
					>
						Copiar copy
					</button>
					<p className="text-[#4131FA] bg-[#F6F5FF] rounded-md inline-block py-2 px-3">
						<Link to={`/post/${dataPost.id}`}>Editar post</Link>
					</p>
				</div>
			</div>

			{/* <pre>{JSON.stringify(dataPost, null, 1)}</pre> */}
			{/* <pre>{JSON.stringify(formEditFieldsFilesLocal, null, 1)}</pre> */}
		</div>
	);
};

export default PostView;
