/* eslint-disable */
import { useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// auth
import { getAuth } from './components/Auth';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { rdxLoginUser, rdxLogoutUser, IRdxUser } from './redux/ducks/User';

//
import { clearLocalStorageAuth } from './helpers/handleStorage';

// router with requirement to access
import ProtectedRoute from './ProtectedRoute';
import UnprotectedRoute from './UnprotectedRoute';

// page public
import PageIndex from './pages/PageIndex';
import PageNotFound from './pages/PageNotFound';

// client
import PageClientHub from './pages/PageClientHub';
import PageClientPost from './pages/PageClientPost';

function Routers() {
	const dispatch = useDispatch();
	const rdxUserisAuth = useSelector((state: IRdxUser) => state.isAuth);
	const [loading, setLoading] = useState<any>(true);

	if (loading) {
		if (!rdxUserisAuth) {
			getAuth().then((responseAuth) => {
				if (responseAuth.data.status === 1) {
					dispatch(rdxLoginUser(responseAuth.data.role, responseAuth.data.email));
				} else {
					clearLocalStorageAuth();
					dispatch(rdxLogoutUser());
				}
				setLoading(false);
			});
		}
		return <p>Loading...</p>;
	}
	return (
		<>
			<BrowserRouter>
				<Switch>
					<UnprotectedRoute exact path="/" component={PageIndex} />
					<Route path="/404" component={PageNotFound} />

					<ProtectedRoute path="/dashboard" component={PageClientHub} />
					<ProtectedRoute path="/post/:pid" component={PageClientPost} />

					<Redirect to="/" />
				</Switch>
			</BrowserRouter>
		</>
	);
}

export default Routers;
