/* eslint-disable */
import { useState } from 'react';
import toast from 'react-hot-toast';

export const copyToClipBoard = async (copyMe) => {
	// const [copySuccess, setCopySuccess] = useState('');
	const toastApi = toast.loading('Atualizando thumb...', {
		position: 'bottom-left',
	});

	try {
		await navigator.clipboard.writeText(copyMe);
		toast.success('Copy copiado!', {
			duration: 5000,
			id: toastApi,
		});
		// setCopySuccess('Copied!');
	} catch (err) {
		toast.error('Copy NÃO copiado!', {
			duration: 5000,
			id: toastApi,
		});
		// setCopySuccess('Failed to copy!');
	}
};

/* eslint-disable */
export const convertMoneyToNumber = (number) => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const convertNumberToMoney = (number) => {
	number = parseInt(number);
	return '$ ' + number.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const convertNumberToPercentage = (number) => {
	return number + ' %';
};

export const convertTimestampTo = (timestamp, timeFormat = 'BRAZIL') => {
	let time = '';
	let a = new Date(timestamp);

	if (isNaN(a)) {
		time = timestamp;
	} else {
		let months = [
			'Jan',
			'Fev',
			'Mar',
			'Abr',
			'Mai',
			'Jun',
			'Jul',
			'Ago',
			'Set',
			'Out',
			'Nov',
			'Dez',
		];
		let year = a.getFullYear();
		let yearShort = year.toString().substr(2);
		let month = months[a.getMonth()];
		let monthNumber = parseInt(a.getMonth() + 1) < 10 ? '0' + (a.getMonth() + 1) : a.getMonth() + 1;
		let date = a.getDate();
		let hour = a.getHours();
		// let hourNumber = parseInt(a.getHours()) < 10 ? '0'+a.getHours() : a.getHours();
		let min = a.getMinutes();
		let minNumber = parseInt(a.getMinutes()) < 10 ? '0' + a.getMinutes() : a.getMinutes();
		let sec = a.getSeconds();

		if (timeFormat === 'BRAZIL') {
			time = date + '/' + monthNumber + '/' + year + ' ' + hour + ':' + minNumber + 'h';
		} else if (timeFormat === 'BRAZIL_SIMPLE') {
			time = date + '/' + monthNumber + '/' + yearShort;
		} else if (timeFormat === 'BR_RESUME') {
			time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + minNumber + ':' + sec;
		} else if (timeFormat === 'BR_COMPLETE') {
			time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + minNumber + ':' + sec;
		} else if (timeFormat === 'BR_COMPLETE_DESCRITIVE') {
			time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + minNumber + 'h';
		}
	}

	return time;
};

export const _formatCurrency = function (amount) {
	// console.log('USSO1', amount);
	if (typeof amount === 'string') {
		const number = amount.replace(/\,/g, '.');
		// console.log('USSO2', number);
		return parseFloat(number)
			.toFixed(2)
			.replace(/(\d)(?=(\d{3})+\,)/g, '$1');
	} else {
		return amount;
	}
};

export const _formatPercentage = function (amount) {
	return parseFloat(amount)
		.toFixed(2)
		.replace(/(\d)(?=(\d{3})+\,)/g, '$1');
};

export const moneyToString = (amount) => {
	// console.log('doam', amount, typeof amount);
	if (typeof amount === 'string') {
		let newString = amount;
		newString = _formatCurrency(newString);
		newString = newString === 'NaN' ? '0,00' : newString;
		newString = newString.replace(/\./g, ',');
		return newString;
	} else {
		return amount;
	}
};
