/* eslint-disable */

import axios from 'axios';
import { getHasLocalStorageAuth } from '../helpers/handleStorage';

import { END_POINT_BASE, IS_DEV } from './Api';

// POST

// GET
export const addPost = async (
	title,
	copy,
	space,
	postChannelFacebook,
	postChannelInstagram,
	postChannelNewsletter,
	postChannelGoogleAds,
	postChannelWhatsapp,
	postChannelTwitter,
	postChannelLinkedin,
	note,
) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const formData = new FormData();
		formData.append('auth_email', localStorageAuth.data.email);
		formData.append('auth_timestamp', localStorageAuth.data.timestamp);
		formData.append('role', localStorageAuth.data.role);
		formData.append('title', title);
		formData.append('copy', copy);
		formData.append('note', note);
		formData.append('space', space);
		formData.append('post_channel_facebook', postChannelFacebook ? 1 : 0);
		formData.append('post_channel_instagram', postChannelInstagram ? 1 : 0);
		formData.append('post_channel_newsletter', postChannelNewsletter ? 1 : 0);
		formData.append('post_channel_googleads', postChannelGoogleAds ? 1 : 0);
		formData.append('post_channel_whatsapp', postChannelWhatsapp ? 1 : 0);
		formData.append('post_channel_twitter', postChannelTwitter ? 1 : 0);
		formData.append('post_channel_linkedin', postChannelLinkedin ? 1 : 0);

		// console.log('do jei', localStorageAuth.data);

		// const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}`;

		const END_POINT = END_POINT_BASE + '/posts/add-post';
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Content-Type': 'multipart/form-data',
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
					'Content-Type': 'multipart/form-data',
			  };

		await axios({
			method: 'post',
			url: END_POINT,
			data: formData,
			headers: axiosHeader,
		})
			.then((response) => {
				// console.log("yeah", response.data.leads.length)
				console.log('yeah', response.data);

				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							post: response.data.post,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// GET
export const getPosts = async () => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}`;

		const END_POINT = END_POINT_BASE + '/leads/leads' + GET_PARAMS;
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		await axios({
			method: 'get',
			url: END_POINT,
			headers: axiosHeader,
		})
			.then((response) => {
				// console.log("yeah", response.data.leads.length)
				console.log('yeah', response.data.leads);

				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							leads: response.data.leads,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// GET
export const getPostsCalendar = async (currentDateStart, currentDateEnd, searchParams) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const GET_PARAMS = `&auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}`;
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		const END_POINT =
			END_POINT_BASE +
			'/posts/events' +
			searchParams +
			GET_PARAMS +
			'&date_start=' +
			currentDateStart +
			'&date_end=' +
			currentDateEnd;
		await axios({
			method: 'get',
			url: END_POINT,
			headers: axiosHeader,
		})
			.then((response) => {
				// console.log("yeah", response.data.leads.length)
				console.log('yeahvend', response.data);

				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							events: response.data.events,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// GET DATA
export const getPostData = async (postId) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}&id=${postId}`;

		const END_POINT = END_POINT_BASE + '/posts/data-post' + GET_PARAMS;
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		await axios({
			method: 'get',
			url: END_POINT,
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('data lead', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							post: response.data.post,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// GET DATA
export const getPostDataUpdates = async (postId) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}&id=${postId}`;

		const END_POINT = END_POINT_BASE + '/posts/data-post-updates' + GET_PARAMS;
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		await axios({
			method: 'get',
			url: END_POINT,
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('data lead', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							post: response.data.post,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// POST edit
export const editDataPost = async (
	postId,
	postStatus,
	postRef,
	postTitle,
	postCopy,
	postNotes,
	postChannels,
	postType,
	postFile,
	postChannelFacebook,
	postChannelInstagram,
	postChannelNewsletter,
	postChannelGoogleAds,
	postChannelWhatsapp,
	postChannelTwitter,
	postChannelLinkedin,
	postSpace,
) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	// console.log(
	// 	'COTIA',
	// 	postChannelFacebook,
	// 	postChannelInstagram,
	// 	postChannelNewsletter,
	// 	postChannelGoogleAds,
	// 	postChannelWhatsapp,
	// );

	if (hasLocalStorageAuth) {
		const formData = new FormData();
		formData.append('auth_timestamp', localStorageAuth.data.timestamp);
		formData.append('auth_email', localStorageAuth.data.email);
		formData.append('post_id', postId);
		formData.append('post_status', postStatus);
		formData.append('post_ref', postRef);
		formData.append('post_title', postTitle);
		formData.append('post_copy', postCopy);
		formData.append('post_notes', postNotes);
		formData.append('post_type', postType);
		formData.append('post_file', postFile);
		formData.append('post_space', postSpace);
		formData.append('post_channel_facebook', postChannelFacebook ? 1 : 0);
		formData.append('post_channel_instagram', postChannelInstagram ? 1 : 0);
		formData.append('post_channel_newsletter', postChannelNewsletter ? 1 : 0);
		formData.append('post_channel_googleads', postChannelGoogleAds ? 1 : 0);
		formData.append('post_channel_whatsapp', postChannelWhatsapp ? 1 : 0);
		formData.append('post_channel_twitter', postChannelTwitter ? 1 : 0);
		formData.append('post_channel_linkedin', postChannelLinkedin ? 1 : 0);
		formData.append('role', localStorageAuth.data.role);

		const END_POINT = END_POINT_BASE + '/posts/edit-post';
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		await axios({
			method: 'post',
			url: END_POINT,
			data: formData,
			// charset: 'utf-8',
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('cjx', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// POST delete
export const removePost = async (postId) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const formData = new FormData();
		formData.append('auth_timestamp', localStorageAuth.data.timestamp);
		formData.append('auth_email', localStorageAuth.data.email);
		formData.append('post_id', postId);
		formData.append('role', localStorageAuth.data.role);

		const END_POINT = END_POINT_BASE + '/posts/remove-post';
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		await axios({
			method: 'post',
			url: END_POINT,
			data: formData,
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('remove post', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// PATCH thumb
export const editPostThumb = async (postId, fileId) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const formData = new FormData();
		formData.append('auth_timestamp', localStorageAuth.data.timestamp);
		formData.append('auth_email', localStorageAuth.data.email);
		formData.append('post_id', postId);
		formData.append('file_id', fileId);
		formData.append('role', localStorageAuth.data.role);

		const END_POINT = END_POINT_BASE + '/posts/edit-post-thumb';
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		await axios({
			method: 'post',
			url: END_POINT,
			data: formData,
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('remove post', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};
