/* eslint-disable */
import React from 'react';

const FilterModal = ({
	handleFilter,
	currentFilter,
	setCurrentFilter,
	closeModal,
	dataClient,
	hasSomeFilter,
	handleResetFilters,
}) => {
	return (
		<form className="flex flex-col items-stretch h-full" onSubmit={handleFilter}>
			<div className="px-5 h-[50px] flex-shrink-0  border-gray-200 flex items-center justify-between">
				<h2 className="text-lg font-bold text-[#FD2E91]">Filtrar busca</h2>
				<button onClick={() => closeModal('MY_MODAL')} className="text-blue-500 hover:underline">
					<i className="bi-x text-4xl" />
				</button>
			</div>

			<div className="basis-full h-full bg-white overflow-y-auto px-3 md:px-5 py-3 md:py-5">
				<div className="container mx-auto md:flex justify-between flex-col space-y-3">
					<div className="flex space-x-4">
						<div className="border p-3 bg-white rounded-md  w-1/3">
							<h3 className="font-bold text-md mb-2">Departamento: </h3>
							<div className="w-full truncate m-0 ">
								<label
									htmlFor="filter_department_1"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_department_1"
										checked={Boolean(currentFilter.department_1)}
										id="filter_department_1"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, department_1: e.target.checked })
										}
									/>
									<span className="text-sm ml-1">Facebook</span>
								</label>
								<label
									htmlFor="filter_department_2"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_department_2"
										checked={Boolean(currentFilter.department_2)}
										id="filter_department_2"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, department_2: e.target.checked })
										}
									/>
									<span className="text-sm ml-1">Whatsapp</span>
								</label>
								<label
									htmlFor="filter_department_3"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_department_3"
										checked={Boolean(currentFilter.department_3)}
										id="filter_department_3"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, department_3: e.target.checked })
										}
									/>
									<span className="text-sm ml-1">Google Ads</span>
								</label>
								<label
									htmlFor="filter_department_4"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_department_4"
										checked={Boolean(currentFilter.department_4)}
										id="filter_department_4"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, department_4: e.target.checked })
										}
									/>
									<span className="text-sm ml-1">Instagram</span>
								</label>
								<label
									htmlFor="filter_department_5"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_department_5"
										checked={Boolean(currentFilter.department_5)}
										id="filter_department_5"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, department_5: e.target.checked })
										}
									/>
									<span className="text-sm ml-1">Newsletter</span>
								</label>
								<label
									htmlFor="filter_department_6"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_department_6"
										checked={Boolean(currentFilter.department_6)}
										id="filter_department_6"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, department_6: e.target.checked })
										}
									/>
									<span className="text-sm ml-1">Twitter</span>
								</label>
								<label
									htmlFor="filter_department_7"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_department_7"
										checked={Boolean(currentFilter.department_7)}
										id="filter_department_7"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, department_7: e.target.checked })
										}
									/>
									<span className="text-sm ml-1">Linkedin</span>
								</label>
								<label
									htmlFor="filter_department_8"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_department_8"
										checked={Boolean(currentFilter.department_8)}
										id="filter_department_8"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, department_8: e.target.checked })
										}
									/>
									<span className="text-sm ml-1">Base</span>
								</label>
							</div>
						</div>
						<div className="border p-3 bg-white rounded-md  w-1/3">
							<h3 className="font-bold text-md mb-2">Spaces: </h3>
							<div className="w-full py-2">
								{dataClient?.spaces.map((space, index) => {
									return (
										<label
											key={`${space.id}-${index}`}
											htmlFor={`filter_space_${space.id}`}
											className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
										>
											<input
												type="checkbox"
												name={`filter_space_${space.id}`}
												checked={Boolean(currentFilter[`space_${index + 1}`])}
												id={`filter_space_${space.id}`}
												onChange={(e) =>
													setCurrentFilter({
														...currentFilter,
														[`space_${index + 1}`]: e.target.checked,
													})
												}
											/>
											<span className="text-sm ml-1 flex items-center">{space.name}</span>
										</label>
									);
								})}
							</div>
						</div>
						<div className="border p-3 bg-white rounded-md w-1/3">
							<h3 className="font-bold text-md mb-2">Status: </h3>
							<div className="w-full py-2">
								<label
									htmlFor="filter_status_1"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_status_1"
										checked={Boolean(currentFilter.status_1)}
										id="filter_status_1"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, status_1: e.target.checked })
										}
									/>
									<span className="text-sm ml-1 flex items-center">
										<div className="bg-yellow-400 rounded-full w-2 h-2 mr-1"></div>Referência
									</span>
								</label>
								<label
									htmlFor="filter_status_2"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_status_2"
										checked={Boolean(currentFilter.status_2)}
										id="filter_status_2"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, status_2: e.target.checked })
										}
									/>
									<span className="text-sm ml-1 flex items-center">
										<div className="bg-gray-300 rounded-full w-2 h-2 mr-1"></div>Rascunho
									</span>
								</label>
								<label
									htmlFor="filter_status_3"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_status_3"
										checked={Boolean(currentFilter.status_3)}
										id="filter_status_3"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, status_3: e.target.checked })
										}
									/>
									<span className="text-sm ml-1 flex items-center">
										<div className="bg-orange-600 rounded-full w-2 h-2 mr-1"></div>Em análise
									</span>
								</label>
								<label
									htmlFor="filter_status_4"
									className="flex items-center bg-white hover:bg-green-200 cursor-pointer px-1 rounded-sm w-1/2 sm:w-1/4 md:w-auto"
								>
									<input
										type="checkbox"
										name="filter_status_4"
										checked={Boolean(currentFilter.status_4)}
										id="filter_status_4"
										onChange={(e) =>
											setCurrentFilter({ ...currentFilter, status_4: e.target.checked })
										}
									/>
									<span className="text-sm ml-1 flex items-center">
										<div className="bg-lime-500  rounded-full bg-kune-500 w-2 h-2 mr-1"></div>
										Aprovado
									</span>
								</label>
							</div>
						</div>
					</div>
					<div className="border p-3 bg-white rounded-md">
						<h3 className="font-bold text-md mb-2">Post: </h3>

						<div className=" w-full md:flex justify-between items-center md:space-x-3">
							<div className="flex flex-col w-full md:w-3/5 ">
								<label htmlFor="filter_name">Título:</label>
								<input
									type="text"
									id="filter_name"
									onChange={(e) => setCurrentFilter({ ...currentFilter, name: e.target.value })}
									value={currentFilter.name ? String(currentFilter.name) : ''}
									className={`border px-2 py-1 rounded-md ${
										String(currentFilter.name).length > 0
											? 'bg-white border-green-500'
											: 'bg-transparent'
									} `}
								/>
							</div>

							<div className="flex flex-col w-full md:w-2/5 ">
								<label htmlFor="filter_id">ID:</label>
								<input
									type="text"
									id="filter_id"
									onChange={(e) => setCurrentFilter({ ...currentFilter, id: e.target.value })}
									value={currentFilter.id ? String(currentFilter.id) : ''}
									className={`border px-2 py-1 rounded-md ${
										String(currentFilter.id).length > 0
											? 'bg-white border-green-500'
											: 'bg-transparent'
									}`}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="px-5 h-[70px] flex-shrink-0 border-t border-gray-200 flex items-center justify-end">
				<div className="flex space-x-3 justify-end">
					{hasSomeFilter && (
						<button
							type="button"
							className="text-[#4131FA] bg-[#F6F5FF] rounded-md inline-block py-2 px-3"
							onClick={() => handleResetFilters()}
						>
							Resetar busca
						</button>
					)}
					<button
						type="submit"
						className="px-3 py-2 w-auto rounded-md bg-[#4131FA] hover:bg-[#3125BC] text-white shadow flex-shrink-0"
					>
						Atualizar busca <i className={`bi-search text-sm`} />
					</button>
				</div>
			</div>

			{/* <pre>{JSON.stringify(dataPost, null, 1)}</pre> */}
			{/* <pre>{JSON.stringify(formEditFieldsFilesLocal, null, 1)}</pre> */}
		</form>
	);
};

export default FilterModal;
