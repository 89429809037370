/* eslint-disable */
/* eslint-disable  operator-linebreak */
import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import { install } from 'resize-observer';

import { getHasLocalStorageAuth } from '../../helpers/handleStorage';
// import { readItemFromStorage } from '../../helpers/handleStorage';

import { IS_DEV } from '../../Api/Api';

// install();
if (typeof window !== 'undefined') {
	// Client-side-only code
	if (!window.ResizeObserver) install();
}

interface IProps {
	//  local data. no need if is getting
	data: any[] | string;
	// change the data in the app to show results
	setData: any;
	// current page
	currentPage?: number;
	// number of results per page
	perPage?: number;
	// scroll to the bottom to load next page
	autoLoad?: boolean;
	// get all the results with one call
	saveLocalJson?: boolean;
	// field of the object ( data.pathData, data.users, data.xxx)
	pathData?: string;
	// callback function after successfully change the page
	callbackChangePage: Function;
	// aditional get data
	params: string;
	// last update
	lastUpdate: string | number;
	// class or id of the element that will calculate scroll to the bottom
	scrollDomReference?: string | null;
	// children
	children: any;
}

interface IRef {
	allContent: number;
	page: number;
	pages: number[];
	viewport: number;
	result: any[];
	dataTemp: any[];
	localData: any[];
	total: number;
}

type serverData = {
	status: number;
	dataRawArr: any[];
	totalResults: number;
};

const Pagination = ({
	data,
	setData,
	currentPage = 1,
	perPage = 10,
	lastUpdate = 0,
	callbackChangePage,
	autoLoad = false,
	saveLocalJson = true,
	pathData = undefined,
	params,
	scrollDomReference = null,
	children,
}: IProps) => {
	const refContentScroll: any = useRef({
		allContent: 0,
		page: currentPage,
		result: [],
		pages: [],
		viewport: 0,
		dataTemp: [],
		localData: [],
		total: [],
	});

	// check if is a endpoint or object
	let dataJson: any[] = [];
	let newContent: any[] = [];
	let pagesList: any[] = [];
	let totalResults: number = 0;
	let isLoadingServerScroll = false;

	const dataRetrieveFrom =
		typeof data === 'object' ? 'LOCAL' : saveLocalJson ? 'SERVER_LOCAL' : 'SERVER';

	// get data from local json
	const getDataFromJson = (json: any[], page: number = 1): any => {
		dataJson = json;

		refContentScroll.current = {
			...refContentScroll.current,
			localData: dataJson,
		};

		const totalResultsLength = dataJson.length;
		const indexCurrent = page * perPage - perPage;
		const indexCurrentFirst = indexCurrent;
		const indexLastResult = indexCurrent + perPage;
		const indexLast = indexLastResult > totalResultsLength ? totalResultsLength : indexLastResult;

		const serverResponse = {
			status: 1,
			dataRawArr: dataJson.slice(indexCurrentFirst, indexLast),
			totalResults: totalResultsLength,
		};
		return serverResponse;
	};

	// get Json from or rest from the endpoint from the server
	const getFullJsonResults = async (page: number): Promise<serverData> => {
		// let localStorageAuth.data.token = null;
		// await readItemFromStorage().then((responseStorage) => {
		// 	localStorageAuth.data.token = responseStorage.data.token;
		// });

		const localStorageAuth = getHasLocalStorageAuth();
		// const hasLocalStorageAuth = localStorageAuth.status;
		// userAuthToken = localStorageAuth.data.token;

		const GET_PARAMS = `&auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}`;

		let serverResponse: serverData = {
			status: 0,
			dataRawArr: [],
			totalResults: 0,
		};

		const axiosHeader: any = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		// getting data from server each page
		if (dataRetrieveFrom === 'SERVER') {
			await axios({
				url: `${data}${params}${GET_PARAMS}&perpage=${perPage}`,
				method: 'get',
				headers: axiosHeader,
			})
				.then((responseData) => {
					// console.log('ven', `${data}?${GET_PARAMS}&page=${page}&perpage=${perPage}&${params}`);
					console.log('dandom', responseData);
					dataJson = pathData ? responseData.data[pathData] : responseData.data;
					totalResults = responseData.data.totalRows;
					serverResponse = {
						status: 1,
						dataRawArr: pathData ? responseData.data[pathData] : responseData.data,
						totalResults: responseData.data.totalRows,
					};
				})
				.catch((errorData) => {
					// console.log('ENDPOINT NOT FOUND', errorData);
				});
		}

		// gettin data from server only the first time
		// after that, the json is treat like local json
		if (dataRetrieveFrom === 'SERVER_LOCAL') {
			if (refContentScroll.current.localData.length === 0) {
				await axios({
					url: `${data}?${params}`,
					method: 'get',
					headers: axiosHeader,
				})
					.then((responseData) => {
						console.log('localiy', responseData);
						dataJson = pathData ? responseData.data[pathData] : responseData.data;
						serverResponse = getDataFromJson(dataJson, page);
					})
					.catch((errorData) => {});
			} else {
				serverResponse = getDataFromJson(refContentScroll.current.localData, page);
			}
		}

		// data is request from the local json passed
		if (dataRetrieveFrom === 'LOCAL') {
			if (typeof data === 'object') {
				dataJson = data;
			}
			serverResponse = getDataFromJson(dataJson, page);
		}

		return serverResponse;
	};

	const getResultPage = (page: number = currentPage) => {
		isLoadingServerScroll = true;
		getFullJsonResults(page).then((responseServerJson) => {
			if (responseServerJson.status === 1) {
				newContent = responseServerJson.dataRawArr;
				totalResults = responseServerJson.totalResults;

				// creating pages to front end
				const totalPages = Math.ceil(totalResults / perPage);
				pagesList = [];
				for (let i = 1; i <= totalPages; i += 1) {
					pagesList.push(i);
				}

				if (autoLoad) {
					refContentScroll.current = {
						...refContentScroll.current,
						page,
						result: [...refContentScroll.current.result, ...newContent],
						pages: pagesList,
						total: totalResults,
					};
				} else {
					refContentScroll.current = {
						...refContentScroll.current,
						page,
						result: newContent,
						pages: pagesList,
						total: totalResults,
					};
				}

				// update useData from the main page
				setData(refContentScroll.current.result);

				// return callback function with current page
				if (callbackChangePage) {
				}
			} else {
				// console.log('COULD NOT GET DATA FROM THIS OBJECT OR THIS ENDPOINT');
			}
			isLoadingServerScroll = false;
		});
		// }
	};

	// click next page
	const handleNewPage = (page: number): void => {
		callbackChangePage(page);
	};

	// click prev page
	const handlePrevPage = (): void => {
		const pageGoesTo = refContentScroll.current.page === 1 ? 1 : refContentScroll.current.page - 1;
		callbackChangePage(pageGoesTo);
	};

	// click number page
	const handleNextPage = (): void => {
		const pageGoesTo =
			refContentScroll.current.page === refContentScroll.current.pages.length
				? refContentScroll.current.page
				: refContentScroll.current.page + 1;
		callbackChangePage(pageGoesTo);
		console.log('h', pageGoesTo);
	};

	useEffect(() => {
		setData(null);
		if (lastUpdate !== null) {
			const scrolling = () => {
				// eventListener scrolling to get bottom of the page
				// only load more results if is not loading something
				// remove event if has got everything
				if (!isLoadingServerScroll) {
					let windowCurrentPosition = 0;
					if (window !== undefined) {
						windowCurrentPosition = window.pageYOffset;
					}
					if (
						refContentScroll.current.viewport + windowCurrentPosition >
						refContentScroll.current.allContent
					) {
						const nextPage = refContentScroll.current.page + 1;
						if (nextPage < refContentScroll.current.pages.length + 1) {
							getResultPage(nextPage);
						} else {
							window.removeEventListener('scroll', scrolling, false);
						}
					}
				}
			};
			if (scrollDomReference != null) {
				const contentScrollBox: Element | null = document.querySelector(scrollDomReference);

				if (autoLoad && contentScrollBox !== null) {
					if (contentScrollBox instanceof HTMLElement) {
						// create an Observer instance
						const resizeObserver = new ResizeObserver(() => {
							refContentScroll.current = {
								...refContentScroll.current,
								allContent: contentScrollBox?.scrollHeight + contentScrollBox?.offsetTop,
								viewport: window?.innerHeight,
							};
						});
						// start observing a DOM node
						resizeObserver.observe(document.body);
						window.addEventListener('scroll', scrolling, false);
					}
				}
			}

			getResultPage();
			return () => {
				window.removeEventListener('scroll', scrolling);
			};
		}

		console.log('DONE  UPDATE', lastUpdate);
	}, [lastUpdate]);

	return (
		<>
			{children}
			<p>{refContentScroll.current.total} resultados</p>
			{autoLoad ? (
				<>
					<p>Scroll to nextpage</p>
				</>
			) : (
				<>
					{Number(refContentScroll.current.pages.length) > 1 && (
						<ul className="flex flex-wrap  my-3 md:my-10 container justify-center items-center">
							{refContentScroll.current.page === 1 ? (
								<li>
									<button
										type="button"
										disabled
										className="h-10 px-3 bg-white border-2 border-gray-300 text-gray-300 rounded-full"
										onClick={handlePrevPage}
									>
										<span className="hidden md:inline-block">Anterior</span>
										<span className="inline-block md:hidden">
											<i className="bi bi-chevron-left" />
										</span>
									</button>
								</li>
							) : (
								<li>
									<button
										type="button"
										className=" h-10 px-3 bg-white border-2 border-main-500 text-main-500 hover:bg-main-100 rounded-full"
										onClick={handlePrevPage}
									>
										<span className="hidden md:inline-block">Anterior</span>
										<span className="inline-block md:hidden">
											<i className="bi bi-chevron-left" />
										</span>
									</button>
								</li>
							)}

							{refContentScroll.current.pages.map((page: number, index: any) => {
								return Number(page) === Number(refContentScroll.current.page) ? (
									<li key={`${index}${page}`}>
										<button
											type="button"
											disabled
											className="w-10 aspect-square bg-main-500 border-2 border-main-500 text-gray-400 bg-gray-100 rounded-full m-1 md:md-0"
											onClick={() => handleNewPage(Number(page))}
										>
											{page}
										</button>
									</li>
								) : Number(page) >= Number(refContentScroll.current.page - 3) &&
								  Number(page) <= Number(refContentScroll.current.page + 3) ? (
									<li key={`${index}${page}`}>
										<button
											type="button"
											className="w-10 aspect-square bg-white border-2 border-main-500 text-main-500 hover:bg-main-100 rounded-full m-1 md:md-0"
											onClick={() => handleNewPage(Number(page))}
										>
											{page}
										</button>
									</li>
								) : (
									(Number(page) === Number(refContentScroll.current.page - 4) ||
										Number(page) === Number(refContentScroll.current.page + 4)) && (
										<li key={page}>
											<button
												type="button"
												disabled
												className="w-10 aspect-square  m-1 md:md-0 h-10 px-3 bg-white border-2  text-gray-300 rounded-full"
											>
												...
											</button>
										</li>
									)
								);
							})}

							{refContentScroll.current.page >= refContentScroll.current.pages.length ? (
								<li>
									<button
										type="button"
										disabled
										className="h-10 px-3 bg-white border-2 border-gray-300 text-gray-300 rounded-full"
										onClick={handleNextPage}
									>
										<span className="hidden md:inline-block">Próximo</span>
										<span className="inline-block md:hidden">
											<i className="bi bi-chevron-right" />
										</span>
									</button>
								</li>
							) : (
								<li>
									<button
										type="button"
										className=" h-10 px-3 bg-white border-2 border-main-500 text-main-500 hover:bg-main-100 rounded-full"
										onClick={handleNextPage}
									>
										<span className="hidden md:inline-block">Próximo</span>
										<span className="inline-block md:hidden">
											<i className="bi bi-chevron-right" />
										</span>{' '}
									</button>
								</li>
							)}
						</ul>
					)}
				</>
			)}
		</>
	);
};

export default Pagination;
