/* eslint-disable */
const FilterButtons = ({
	currentModeview,
	currentOrder,
	hasSomeFilter,
	setIsFilterOpened,
	isFilterOpened,
	handleMode,
	openModal,
	handleOrder,
}) => {
	return (
		<div className="flex space-x-3">
			{currentModeview === 1 && (
				<form className="md:flex justify-end  items-center w-1/2 md:w-auto">
					<select
						name=""
						id="order"
						onChange={(e) => handleOrder(e)}
						value={currentOrder}
						className="bg-[#F6F6F6] hover:bg-[#EDEDED] w-full  rounded-lg py-2 px-2"
					>
						<option value="1">Mais recentes</option>
						<option value="2">Mais antigos</option>
						<option value="4">Maior avaliação</option>
						<option value="3">Menor avaliação</option>
						<option value="6">Status pelo maior</option>
						<option value="5">Status pelo menor</option>
						<option value="7">Atualizado mais recente</option>
						<option value="8">Atualizado mais antigo</option>
					</select>
				</form>
			)}
			<button
				type="button"
				className={` ${
					hasSomeFilter ? ' bg-[#454545] text-white' : 'bg-[#F6F6F6] hover:bg-[#EDEDED]'
				} py-1 px-2 rounded-md space-x-2`}
				// onClick={() => setIsFilterOpened(!isFilterOpened)}
				onClick={() => openModal('MY_MODAL')}
			>
				<i className="bi-filter text-xl" />
			</button>

			<div className="flex">
				<button
					type="button"
					className={` ${
						currentModeview === 1 ? ' bg-[#454545] text-white' : 'bg-[#F6F6F6] hover:bg-[#EDEDED]'
					} py-1 px-2 rounded-l-md space-x-2`}
					onClick={() => handleMode(1)}
				>
					<i className="bi-table text-lg" />
				</button>

				<button
					type="button"
					className={` ${
						currentModeview === 2 ? ' bg-[#454545] text-white' : 'bg-[#F6F6F6] hover:bg-[#EDEDED]'
					} py-1 px-2 rounded-r-md space-x-2`}
					onClick={() => handleMode(2)}
				>
					<i className="bi-calendar3 text-lg" />
				</button>
			</div>

			<button
				type="button"
				className=" bg-[#4131FA] hover:bg-[#3125BC] text-white py-1 px-3 rounded-lg space-x-2"
				onClick={() => openModal('SECOND_MODAL')}
			>
				<span>Criar post</span>
				<span>
					<i className="bi bi-plus-lg text-lg" />
				</span>
			</button>
		</div>
	);
};

export default FilterButtons;
