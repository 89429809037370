/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line */
import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';

// import { Helmet } from 'react-helmet';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment-timezone';

import { getHasLocalStorageAuth } from '../../helpers/handleStorage';
// import Placeholder from '../Placeholder';
// import HeaderTop from '../HeaderTop/HeaderTop';
// import ModalCustom from '../ModalCustom';
// import RateStar from '../RateStar';

import { getLeadData } from '../../Api/clientLeadsHandle';
// import PaginationJson from '../PaginationJson';

import { END_POINT_BASE } from '../../Api/Api';

import { getClientData } from '../../Api/clientsClientHandle';
// import Footer from '../Footer/Footer';

import 'bootstrap-icons/font/bootstrap-icons.css';
// import LeadEdit from '../LeadEdit/LeadEdit';
// import LeadAddForm from '../LeadAddForm';
// import PostView from '../PostView/PostView';
import { getPostsCalendar } from '../../Api/clientPostsHandle';
import FilterButtons from '../FilterButtons/FilterButtons';
// import CalendarView from '../CalendarView/CalendarView';

const CalendarView = ({
	lastUpdate,
	params,
	currentMonth,
	callbackChangeCalendarday,
	setFormFieldsPostIdLocal,
	openModalLead,
	currentModeview,
	currentOrder,
	hasSomeFilter,
	setIsFilterOpened,
	isFilterOpened,
	handleMode,
	openModal,
	handleOrder,
}) => {
	// const history = useHistory();
	// console.log(params)
	// // calendar
	// const [currentMonth, setCurrentMonth] = useState(currentMonth);
	const [currentMonthName, setCurrentMonthName] = useState();
	const [isLoadingEvents, setIsLoadingEvents] = useState(false);

	const localizer = momentLocalizer(moment);

	const [dataCalendarResult, setDataCalendarResult] = useState(null); // calendar data result (events)

	// //
	const message = {
		date: 'Data',
		time: 'Horário',
		event: 'Evento',
		allDay: 'Todos os dias',
		week: 'Semana',
		work_week: 'Dia útil',
		day: 'Dia',
		month: 'Mês',
		previous: 'Voltar',
		next: 'Próximo',
		yesterday: 'Ontem',
		tomorrow: 'Amanhã',
		today: 'Hoje',
		agenda: 'Agenda',
		noEventsInRange: 'Não há eventos.',
		showMore: (total) => `+${total} mais`,
	};

	// get calendar events
	const loadingClientEvents = async (month = moment()) => {
		setIsLoadingEvents(true);
		await getPostsCalendar(
			moment(month).format('YYYY-MM-01'),
			moment(month).format('YYYY-MM-31'),
			params,
		).then((responseData) => {
			if (responseData.data.status === 1) {
				setDataCalendarResult(responseData.data.events);
			}
			setIsLoadingEvents(false);
		});
	};

	// prev month
	const setNextMonth = () => {
		const nextDate = moment(currentMonth).add('1', 'M');
		callbackChangeCalendarday(nextDate);
	};

	// prev month
	const setPrevMonth = () => {
		const prevDate = moment(currentMonth).subtract('1', 'M');
		callbackChangeCalendarday(prevDate);
	};

	// today
	const setTodayMonth = () => {
		callbackChangeCalendarday(moment());
	};

	useEffect(() => {
		setCurrentMonthName(moment(currentMonth).format('MMMM / Y'));
		loadingClientEvents(currentMonth);
	}, [lastUpdate]);

	if (!dataCalendarResult) {
		return false;
	}

	return (
		<>
			<div className="flex justify-between items-center  mb-1 md:mb-3 my-1 md:my-5">
				<div className="flex justify-between items-center space-x-3">
					<h2 className="font-bold text-md md:text-2xl text-[#FD2E91]">
						{currentModeview === 1 ? 'Postagens' : 'Calendário'}
					</h2>
				</div>

				<div className="flex justify-end space-x-3">
					{!isLoadingEvents && (
						<div className="flex">
							<button
								type="button"
								className="bg-[#F6F6F6] hover:bg-[#EDEDED] py-1 px-2 rounded-l-md space-x-2"
								onClick={setPrevMonth}
							>
								<i className="bi-chevron-left text-sm" />
							</button>
							<button
								type="button"
								className="bg-[#F6F6F6] hover:bg-[#EDEDED] py-1 px-2  space-x-2"
								onClick={setTodayMonth}
							>
								{currentMonthName}
							</button>
							<button
								type="button"
								className="bg-[#F6F6F6] hover:bg-[#EDEDED] py-1 px-2 rounded-r-md space-x-2"
								onClick={setNextMonth}
							>
								<i className="bi-chevron-right text-sm" />
							</button>
						</div>
					)}

					<FilterButtons
						currentModeview={currentModeview}
						currentOrder={currentOrder}
						hasSomeFilter={hasSomeFilter}
						setIsFilterOpened={setIsFilterOpened}
						isFilterOpened={isFilterOpened}
						handleMode={handleMode}
						openModal={openModal}
						handleOrder={handleOrder}
					/>
				</div>
			</div>

			<div className="flex space-x-3 items-center justify-end mb-3"></div>

			<div className="custom-height">
				<Calendar
					localizer={localizer}
					messages={message}
					events={dataCalendarResult}
					onNavigate={() => {
						console.log('mudou calendario');
					}}
					date={currentMonth}
					views={['month']}
					defaultView="month"
					startAccessor="start"
					endAccessor="start"
					onView={() => {
						console.log('done');
					}}
					onSelectEvent={({ title, id, url }) => {
						setFormFieldsPostIdLocal(id);
						openModalLead();
					}}
					popup={true}
					toolbar={false}
					titleAccessor={({ title, url }) => (
						<div className="bg-gray-100">
							<img
								src={url}
								alt="title logo"
								style={{
									width: '100%',
									height: '100%',
									backgroundColor: 'black',
								}}
							/>
							<span>{title}</span>
						</div>
					)}
					style={{ height: 850 }}
				/>
			</div>
		</>
	);
};

export default CalendarView;
