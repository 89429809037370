/* eslint-disable */

import axios from 'axios';
import { getHasLocalStorageAuth } from '../helpers/handleStorage';

import { END_POINT_BASE, IS_DEV } from './Api';

// GET
export const getLeads = async () => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}`;
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		const END_POINT = END_POINT_BASE + '/leads/leads' + GET_PARAMS;
		await axios({
			method: 'get',
			url: END_POINT,
			headers: axiosHeader,
		})
			.then((response) => {
				// console.log("yeah", response.data.leads.length)
				console.log('yeah', response.data.leads);

				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							leads: response.data.leads,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// GET DATA
export const getLeadData = async (leadId) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&role=${localStorageAuth.data.role}&id=${leadId}`;
		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		const END_POINT = END_POINT_BASE + '/leads/data-lead' + GET_PARAMS;
		await axios({
			method: 'get',
			url: END_POINT,
			headers: axiosHeader,
		})
			.then((response) => {
				// console.log('data lead', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							lead: response.data.lead,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// POST edit
export const editDataLead = async (leadId, rate, comment) => {
	const localStorageAuth = getHasLocalStorageAuth();
	const hasLocalStorageAuth = localStorageAuth.status;
	let serverResponse = { data: { status: 0 } };

	if (hasLocalStorageAuth) {
		const formData = new FormData();
		formData.append('auth_timestamp', localStorageAuth.data.timestamp);
		formData.append('auth_email', localStorageAuth.data.email);
		formData.append('lead_id', leadId);
		formData.append('rate', rate);
		formData.append('comment', comment);
		formData.append('role', localStorageAuth.data.role);

		const END_POINT = END_POINT_BASE + '/leads/edit-lead';

		const axiosHeader = IS_DEV
			? {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
			  }
			: {
					Authorization: `Bearer ${localStorageAuth.data.token}`,
					'Cache-Control': 'no-cache',
					Pragma: 'no-cache',
					Expires: '0',
			  };

		await axios({
			method: 'post',
			url: END_POINT,
			data: formData,
			headers: axiosHeader,
		})
			.then((response) => {
				console.log('cj', response);
				// 1 - done
				if (response.data.status === 1) {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				} else {
					serverResponse = {
						data: {
							status: response.data.status,
							message: response.data.message,
						},
					};
				}
			})
			.catch((error) => {});
	}

	return serverResponse;
};

// // GET DATA
// export const getPaymentClientData = async (paymentId) => {
// 	const localStorageAuth = getHasLocalStorageAuth();
// 	const hasLocalStorageAuth = localStorageAuth.status;
// 	let serverResponse = { data: { status: 0 } };

// 	if (hasLocalStorageAuth) {
// 		const GET_PARAMS = `?auth_email=${localStorageAuth.data.email}&auth_timestamp=${localStorageAuth.data.timestamp}&payment_id=${paymentId}&role=${localStorageAuth.data.role}`;

// 		const END_POINT = END_POINT_BASE + '/payments/data-client-payment' + GET_PARAMS;
// 		await axios({
// 			method: 'get',
// 			url: END_POINT,
// 			headers: { Authorization: `Bearer ${localStorageAuth.data.token}` },
// 		})
// 			.then((response) => {
// 				// 1 - done
// 				if (response.data.status === 1) {
// 					serverResponse = {
// 						data: {
// 							status: response.data.status,
// 							payment: response.data.payment,
// 							message: response.data.message,
// 						},
// 					};
// 				} else {
// 					serverResponse = {
// 						data: {
// 							status: response.data.status,
// 							message: response.data.message,
// 						},
// 					};
// 				}
// 			})
// 			.catch((error) => {});
// 	}

// 	return serverResponse;
// };
