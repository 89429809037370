/* eslint-disable */
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

const PageNotFound = () => {
	return (
		<>
			<h1>404</h1>
			<p>Página não encontrada</p>
		</>
	);
};

export default PageNotFound;
