/* eslint-disable react/prop-types */
const RateStar = ({ rate }) => {
	return (
		<div className="flex space-x-2">
			{rate >= 1 ? <i className="bi bi-star-fill" /> : <i className="bi bi-star" />}
			{rate >= 2 ? <i className="bi bi-star-fill" /> : <i className="bi bi-star" />}
			{rate >= 3 ? <i className="bi bi-star-fill" /> : <i className="bi bi-star" />}
			{rate >= 4 ? <i className="bi bi-star-fill" /> : <i className="bi bi-star" />}
			{rate >= 5 ? <i className="bi bi-star-fill" /> : <i className="bi bi-star" />}
		</div>
	);
};

export default RateStar;
